import _ from 'lodash';
import { closeCharacterSwapDialog } from "@/services/actions/commonActions";
import { useDispatch, useNavigateWithQueryAndHash, useSelector } from "@/services/hooks";
import { Box, Button, DialogContent, Stack, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { color } from '@/services/themes';
import TagManager from 'react-gtm-module';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
// import RevivalPaymentBanner from '@/components/Banner/RevivalPaymentBanner';


export default function SwitchDialog() {
  const dispatch = useDispatch();
  const navigateTo = useNavigateWithQueryAndHash();
  const { character_swap_dialog } = useSelector((state) => state.common);
  const { user, detail } = useSelector((state) => state.user);
  const remain = _.get(detail, 'swap.item_remain', 0) + _.get(detail, 'swap.plan_remain', 0)
  const usePoint = _.get(character_swap_dialog, 'point', 0);

  const closeDialog = () => {
    dispatch(closeCharacterSwapDialog());
  };

  return (
    <DialogContent
      sx={{
        flexDirection: 'column',
        alignItems: 'flex-start',
        alignSelf: 'stretch',
        padding: 0,
      }}
    >
      <Stack gap={2} p={2}>
        <Typography
          fontWeight="bold"
          fontSize="18px"
          color="textPrimary"
          textAlign="center"
        >
          <FormattedMessage id="switch.dialog.lack.title" />
        </Typography>

        <Stack gap={3}>
          <Stack gap={1}>
            <Stack direction="row" alignItems="center">
              <Typography
                fontSize="16px"
                fontWeight="regular"
                color="#000000"
                lineHeight={1}
              >
                <FormattedMessage id="switch.dialog.lack.require_point" />：
              </Typography>
              <Typography
                fontSize="20px"
                fontWeight="bold"
                color="primary"
                lineHeight={1}
              >
                {usePoint}pt
              </Typography>
            </Stack>

            <Stack direction="row" alignItems="center">
              <Typography
                fontSize="16px"
                fontWeight="regular"
                color="#000000"
                lineHeight={1}
              >
                <FormattedMessage id="switch.dialog.lack.my_point" />：
              </Typography>
              <Typography
                fontSize="16px"
                fontWeight="regular"
                color="#000000"
                lineHeight={1}
              >
                {remain}pt
              </Typography>
            </Stack>

          </Stack>
          <Box
            position="relative"
            sx={{
              height: 'fit-content',
            }}
          >
            <img style={{ width: '100%', height: 'auto', display: 'block', }} src="/dialog/switch/PointGetBan@2x.png" srcSet="/dialog/switch/PointGetBan@2x.png 2x,/dialog/switch/PointGetBan@2x.png 3x" alt="banner" />

            <Box
              sx={{
                position: 'absolute',
                width: '100%',
                bottom: '4px',
                left: 0,
                padding: '0 13px',
              }}
            >
              <Button
                variant="contained"
                color="primary"
                fullWidth
                endIcon={<ArrowForwardIosIcon />}
                onClick={() => {
                  closeDialog()
                  navigateTo('/users/plan')
                  TagManager.dataLayer({
                    dataLayer: {
                      event: "gtm_event",
                      ga_event: "move_plan_from_swich_dialog",
                      user_id: user?.id,
                    }
                  });
                }}
              >
                <FormattedMessage id="switch.dialog.lack.button" />
              </Button>
            </Box>
          </Box>
        </Stack>

        <Button
          variant="outlined"
          color="inherit"
          fullWidth
          onClick={closeDialog}
          sx={{
            borderColor: '#E5E5E5',
            color: 'rgba(0, 0, 0, 0.87)',
          }}
        >
          <FormattedMessage id="switch.dialog.lack.cancel_button" />
        </Button>

        <Stack gap="9px">
          <Typography
            fontSize="16px"
            fontWeight="bold"
            textAlign="center"
          >
            <FormattedMessage id="switch.dialog.lack.merit1" />
            <Box display="inline" sx={{ color: color.main }}><FormattedMessage id="switch.dialog.lack.merit2" /></Box>
            <FormattedMessage id="switch.dialog.lack.merit3" />
          </Typography>

          <Stack spacing={1} sx={{ pl: 1 }}>
            <Stack direction="row" alignItems="center">
              <Typography sx={{ color: color.main, fontSize: "8px" }}>●</Typography>
              <Typography fontSize="14px" sx={{ ml: 1 }}><FormattedMessage id="switch.dialog.lack.merit_text1" /></Typography>
            </Stack>
            <Stack direction="row" alignItems="center">
              <Typography sx={{ color: color.main, fontSize: "8px" }}>●</Typography>
              <Typography fontSize="14px" sx={{ ml: 1 }}><FormattedMessage id="switch.dialog.lack.merit_text2" /></Typography>
            </Stack>
            <Stack direction="row" alignItems="center">
              <Typography sx={{ color: color.main, fontSize: "8px" }}>●</Typography>
              <Typography fontSize="14px" sx={{ ml: 1 }}><FormattedMessage id="switch.dialog.lack.merit_text3" /></Typography>
            </Stack>
          </Stack>

        </Stack>
      </Stack>

    </DialogContent >
  );
}