import { Stack, Typography } from "@mui/material";
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import { FormattedMessage } from "react-intl";
import { useSelector } from "@/services/hooks";

export default function SwitchSceneHint() {
  const user = useSelector(state => state.user.user);
  if (!user?.tutorial_step || user?.tutorial_step !== 'switch_scene') {
    return null;
  }
  return (
    <>
      <Stack
        position="absolute"
        padding="14px 24px"
        gap={1}
        width="256px"
        maxWidth="100%"
        sx={{
          backgroundColor: '#FFF',
          bottom: 'calc(100%)',
          left: '0',
          right: '0',
          margin: '0 auto !important',
          borderRadius: '16px',
          zIndex: 101,
          boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, .25)',
          '&::before': {
            content: '""',
            position: 'absolute',
            bottom: '-8px',
            left: '50%',
            transform: 'translateX(-50%)',
            width: '0',
            height: '0',
            borderLeft: '8px solid transparent',
            borderRight: '8px solid transparent',
            borderTop: '8px solid #FFF',
          },
        }}
      >
        <Typography
          variant="body1"
          color="rgba(0, 0, 0, 0.60)"
          sx={{
            fontSize: '10px',
            lineHeight: 1.5,
            display: 'flex',
            alignItems: 'center',
            gap: '4px',
          }}
        >
          <TipsAndUpdatesIcon fontSize="small" sx={{ color: "#FFD54F" }} />
          <FormattedMessage id="hint.switch.how_to_use" />
        </Typography>

        <Typography
          variant="body1"
          sx={{
            fontWeight: 700,
          }}
        >
          顔写真を選択してスイッチ動画を作成しよう！
        </Typography>
      </Stack>
      {/* マスク */}
      <div
        style={{
          position: 'fixed',
          background: 'rgba(0, 0, 0, 0.5)',
          width: '100vw',
          height: '100vh',
          top: 0,
          left: 0,
          zIndex: 100,
          margin: 0,
        }}
      />
    </>
  )
}