import { Box, IconButton, Typography } from '@mui/material';
import {
  useDispatch,
  useSelector
} from '@/services/hooks';
import TagManager from "react-gtm-module";
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
import { color } from '@/services/themes';
import { closeTimesaleCountdown } from '@/services/actions/commonActions';
import { useEffect, useState } from 'react';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { backToPlanPagePath } from '@/constants';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

const TimesaleCountdown = () => {
  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  const { detail, user } = useSelector(state => state.user);
  const [startTime, setStartTime] = useState(Date.now());
  const [baseRemainingTime, setBaseRemainingTime] = useState((detail?.timesale_remain_sec || 0) * 1000);
  const [remainingTime, setRemainingTime] = useState((detail?.timesale_remain_sec || 0) * 1000);

  useEffect(() => {
    if (detail && detail?.timesale_remain_sec > 0) {
      setStartTime(Date.now())
      setBaseRemainingTime(detail.timesale_remain_sec * 1000)
      setRemainingTime(detail.timesale_remain_sec * 1000);
    } else {
      dispatch(closeTimesaleCountdown())
    }
  }, [detail?.timesale_remain_sec]);

  useEffect(() => {
    const startTime = Date.now();
    const intervalId = setInterval(() => {
      const elapsed = Date.now() - startTime;
      setRemainingTime(Math.max(baseRemainingTime - elapsed, 0));
    }, 17);

    return () => clearInterval(intervalId);
  }, [startTime]);

  useEffect(() => {
    if (remainingTime <= 0) {
      dispatch(closeTimesaleCountdown());
      return;
    }
  }, [remainingTime])


  const min = Math.floor(remainingTime / 60000);
  const sec = Math.floor(remainingTime % 60000 / 1000);
  const milsec = Math.floor(remainingTime % 1000 / 10);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{
        background: '#FAED19',
        paddingY: '8px',
        height: '40px', //不要だけど一応わかりやすく
      }}
    >
      <Box
        width="100%"
        maxWidth="375px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        gap="4px"
      >
        <AccessAlarmsIcon
          sx={{
            color: color.main,
            fontSize: '24px',
          }}
        />
        <Typography
          variant="body2"
          fontWeight="Medium"
        >
          <FormattedMessage id="timesale.countdown.title" />
        </Typography>

        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          gap="4px"
          marginLeft="4px"
        >
          <Box
            sx={{
              background: 'rgba(0, 0, 0, 0.87)',
              padding: '1px 4px',
              borderRadius: '3px',
            }}
          >
            <Typography
              color="#FFFFFF"
              fontFamily="Roboto"
              fontSize="20px"
              fontWeight="Medium"
            >
              {min.toString().padStart(2, '0')}
            </Typography>
          </Box>

          <Typography
            color="rgba(0, 0, 0, 0.87)"
            fontFamily="Roboto"
            fontSize="20px"
            fontWeight="Medium"
          >
            :
          </Typography>

          <Box
            sx={{
              background: 'rgba(0, 0, 0, 0.87)',
              padding: '1px 4px',
              borderRadius: '3px',
            }}
          >
            <Typography
              color="#FFFFFF"
              fontFamily="Roboto"
              fontSize="20px"
              fontWeight="Medium"
            >
              {sec.toString().padStart(2, '0')}
            </Typography>
          </Box>

          <Typography
            color="rgba(0, 0, 0, 0.87)"
            fontFamily="Roboto"
            fontSize="20px"
            fontWeight="Medium"
          >
            :
          </Typography>

          <Box
            sx={{
              background: 'rgba(0, 0, 0, 0.87)',
              padding: '1px 4px',
              borderRadius: '3px',
            }}
          >
            <Typography
              color="#FFFFFF"
              fontFamily="Roboto"
              fontSize="20px"
              fontWeight="Medium"
            >
              {milsec.toString().padStart(2, '0')}
            </Typography>
          </Box>
        </Box>
        <IconButton
          color="primary"
          onClick={() => {
            navigateTo(backToPlanPagePath)
            TagManager.dataLayer({
              dataLayer: {
                event: "gtm_event",
                ga_event: "move",
                user_id: user?.id,
                from: 'timesale_countdown',
                to: backToPlanPagePath,
              }
            });
          }}
          sx={{
            marginLeft: '23px',
            width: '20px',
            height: '20px',
            background: color.main,
            '&:hover': {
              background: color.main,
              filter: 'brightness(1.5)',
            }
          }}
        >
          <KeyboardArrowRightIcon
            sx={{
              color: '#FFFFFF',
              fontSize: '20px',
            }}
          />
        </IconButton>
      </Box>
    </Box>
  );
};

export default TimesaleCountdown;
