import { completeTutorialStep } from "@/services/actions/userActions";
import { useDispatch } from "@/services/hooks";
import { Button, Dialog, DialogActions, DialogContent, Stack, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

type Pros = {
  open: boolean;
  closeDialog: () => void;
}

export default function FindMoreScenesDialog({
  open,
  closeDialog,
}: Pros) {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  return <Dialog open={open} PaperProps={{ sx: { width: "343px" } }}>
    <DialogContent sx={{ paddingBottom: 0 }}>
      <Stack gap={2} alignItems="center">
        <Typography variant="h6">
          <FormattedMessage id="tutorial.find_more_scenes" />
        </Typography>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={() => {
            closeDialog();
            navigate('/?tab=recommend');
            dispatch(completeTutorialStep({ tutorialStep: 'find_more_scenes' }));
          }}
        >
          <FormattedMessage id="tutorial.find_more_scenes.button" />
        </Button>
      </Stack>
    </DialogContent>
    <DialogActions>
      <Button
        variant="text"
        onClick={() => {
          closeDialog();
          dispatch(completeTutorialStep({ tutorialStep: 'find_more_scenes' }));
        }}
      >
        <FormattedMessage id="close" />
      </Button>
    </DialogActions>
  </Dialog>
}
