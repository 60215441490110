import { Stack, Typography, Fade } from "@mui/material";
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import { FormattedMessage } from "react-intl";
import { useSelector } from "@/services/hooks";

export default function InputCharacterNameHint() {
  const user = useSelector(state => state.user.user);
  const isTarget = user?.tutorial_step && user?.tutorial_step === 'switch_scene'
  if (!isTarget) {
    return null;
  }
  return (
    <>
      <Fade in unmountOnExit timeout={300}>
        <Stack
          position="absolute"
          padding="14px 24px"
          gap={1}
          width="256px"
          sx={{
            backgroundColor: '#FFF',
            bottom: 'calc(100% + 16px)',
            right: 0,
            borderRadius: '16px',
            zIndex: 1201,
            boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, .25)',
            '&::before': {
              content: '""',
              position: 'absolute',
              bottom: '-8px',
              right: '30%',
              transform: 'translateX(-50%)',
              width: '0',
              height: '0',
              borderLeft: '8px solid transparent',
              borderRight: '8px solid transparent',
              borderTop: '8px solid #FFF',
            },
          }}
        >
          <Typography
            variant="body1"
            color="rgba(0, 0, 0, 0.60)"
            sx={{
              fontSize: '10px',
              lineHeight: 1.5,
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
            }}
          >
            <TipsAndUpdatesIcon fontSize="small" sx={{ color: "#FFD54F" }} />
            <FormattedMessage id="hint.switch.how_to_use" />
          </Typography>

          <Typography
            variant="body1"
            sx={{
              fontWeight: 700,
            }}
          >
            顔写真にネームを設定しましょう
            {/* <FormattedMessage id="hint.switch.lets" /> */}
          </Typography>
        </Stack>
      </Fade>
      {/* マスク */}
      <div
        style={{
          position: 'fixed',
          background: 'rgba(0, 0, 0, 0.5)',
          width: '100vw',
          height: '100vh',
          top: 0,
          left: 0,
          zIndex: 1200,
          margin: 0,
        }}
      />
    </>
  )
}