import { axios } from '@/services/api';
import { Character } from '@/services/models';
import Axios from 'axios';
import { Dispatch } from 'redux';

export const getCharacters = () => async (dispatch: Dispatch): Promise<Character[]|null> => {
  try {
    const url = '/api/v1/characters/my';
    const { data } = await axios.get(url, {withCredentials: true});
    dispatch({ type: 'GET_CHARACTERS_SUCCESS', payload: data });
    return data;
  } catch (e: unknown) {
    if (Axios.isAxiosError(e)) {
      dispatch({ type: 'GET_CHARACTERS_FAIL', payload: e.message });
    }
    return null;
  }
};
