import {
  DialogTitle,
  DialogContent,
  Button,
  Stack,
  Box, Typography,
} from '@mui/material';
import _ from 'lodash';

import { Item } from '@/services/models';
import { useDispatch, useNavigateWithQueryAndHash, useSelector } from '@/services/hooks';
import { closeCharacterSwapDialog, openAlertModal, openSnackbar, setProgressPolling } from '@/services/actions/commonActions';
import { useEffect, useMemo, useState } from 'react';
import { ApiService } from '@/services/api';
import { completeTutorialStep, getUserDetail } from '@/services/actions/userActions';
import { backToMypagePath, backToPlanPagePath } from '@/constants';
import CharacterList from '@/components/CharacterList';
import { FormattedMessage, useIntl } from 'react-intl';
import TagManager from "react-gtm-module";
import { startProgressSession } from '@/services/progress';
import { getLocale } from "@/utils";
import { getCharacters } from '@/services/actions/characterActions';
import SwitchSceneHint from '@/components/common/Tutorial/SwitchSceneHint';
import { useTutorialAlmostDone } from '@/services/models';

export default function CharacterSwapDialog() {
  const dispatch = useDispatch();
  const navigateTo = useNavigateWithQueryAndHash();
  const { formatMessage } = useIntl();
  const locale = getLocale()

  const [loading, setLoading] = useState<boolean>(false);
  const [characterId, setCharacterId] = useState<number | null>(null);

  const { user, detail } = useSelector((state) => state.user);
  const remain = _.get(detail, 'swap.item_remain', 0) + _.get(detail, 'swap.plan_remain', 0)
  const { character_swap_dialog } = useSelector((state) => state.common);
  const usePoint = character_swap_dialog.point;
  const isSample = !!character_swap_dialog.video_uid;
  const isLackPoint = remain < usePoint;
  const disabled = !characterId || isLackPoint || loading;
  const characters = useSelector((state) => state.character.characters);
  const isTutorialAlmostDone = useTutorialAlmostDone();
  const isSwitchSceneTutorial = user?.tutorial_step === 'switch_scene' && characters.length > 0;
  useEffect(() => {
    if (characters.length === 1) {
      setCharacterId(characters[0].id);
    }
  }, [characters.length])

  const [items, setItems] = useState<Item[]>([]);

  const isShowItemBanner = useMemo(() => {
    return _.some(items, (item) => {
      return item.purchase_limit === 1 && item.price === '2.00';
    })
  }, [items])

  useEffect(() => {
    ApiService.fetchItems().then((data) => {
      setItems(data);
    })
  }, [user])

  const closeDialog = () => {
    dispatch(closeCharacterSwapDialog());
  };

  const submitGTM = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "swap",
        user_id: user?.id,
        is_free_plan: user?.is_free_plan,
        scene_id: character_swap_dialog.scene_id,
        is_tutorial: isSwitchSceneTutorial,
      }
    });
  }

  useEffect(() => {
    if (!character_swap_dialog.open) {
      return;
    }
    dispatch(getUserDetail())
    dispatch(getCharacters())
  }, [character_swap_dialog.open, dispatch]);

  const handleSubmit = async (e: React.MouseEvent) => {
    e.stopPropagation();
    setLoading(true);
    try {
      if (!isTutorialAlmostDone) {
        await dispatch(completeTutorialStep({ tutorialStep: 'switch_scene' }));
      }
      const response = await ApiService.generate(Number(characterId), character_swap_dialog.scene_id);
      if (!response.success) {
        const errorMessage = formatMessage({ id: `generate.error.${response.error_type}`, defaultMessage: "Error" });
        // 特定のエラーの場合はモーダルで表示
        if (response.error_type === 'over_parallel_limit') {
          dispatch(openAlertModal(errorMessage));
        } else {
          dispatch(openSnackbar(errorMessage));
        }
      } else {
        closeDialog();
        submitGTM()
        dispatch(setProgressPolling(true));
        startProgressSession(); // sessionに開始したことを保存
        dispatch(getUserDetail());
        if (user?.is_free_plan) {
          navigateTo('/?tab=switched');
        } else {
          navigateTo('/?switched=true');
        }
      }
    } catch (error: unknown) {
      console.log("generated error: ", error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <DialogTitle
        align="center"
        sx={{
          fontSize: '16px',
          lineHeight: 'normal',
          letterSpacing: '0.15px',
          margin: '0 0 24px',
          padding: '0',
        }}
      >
        <FormattedMessage id="character_swap.title" />
      </DialogTitle>
      <DialogContent
        sx={{
          flexDirection: 'column',
          alignItems: 'flex-start',
          alignSelf: 'stretch',
          padding: 0,
          overflow: 'visible',
        }}
      >
        <Box marginBottom={2}>
          <CharacterList
            isLogin={!!detail}
            characterId={characterId}
            createOnClick={(characterId: number) => {
              return () => {
                setCharacterId(characterId);
              }
            }}
            closeDialog={closeDialog}
          />
        </Box>

        {!isSample && (
          <Typography variant="body1">
            <FormattedMessage id="character_swap.message1" />
            <br />
            <br />
            <FormattedMessage id="character_swap.message2" />{"："}{remain}
            <br />
            <FormattedMessage id="character_swap.message3" />{"："}{usePoint}
            {isLackPoint && (
              <>
                <br />
                <br />
                <FormattedMessage id="character_swap.lack_point" />
              </>
            )}
          </Typography>
        )}

        <Stack
          spacing={2}
          justifyContent="start-flex"
          margin="32px 16px 16px"
          position="relative"
        >
          {isSample || isLackPoint && user?.is_free_plan ? (
            <>
              {
                isShowItemBanner && !isSample && (
                  <>
                    <Typography
                      variant="body2"
                    >
                      <FormattedMessage id="character_swap.one_time_item.text" />
                    </Typography>
                    <Button
                      sx={{ marginTop: '8px !important' }}
                      onClick={() => {
                        navigateTo(backToMypagePath)
                        TagManager.dataLayer({
                          dataLayer: {
                            event: "gtm_event",
                            ga_event: "move_plan_from_switch_button",
                            character_id: characterId,
                            user_id: user?.id,
                          }
                        });
                        closeDialog();
                      }}
                      fullWidth
                      variant="contained"
                      size="large"
                      color="tertiary"
                    >
                      {
                        locale === 'ja' ? (
                          <>
                            少額でSwitch（初回限定
                            <span style={{ color: '#FFF78C' }}>
                              <span style={{ textDecoration: 'line-through' }}>$7</span> ⇒ $2
                            </span>
                            ）
                          </>
                        ) : (
                          <>
                            Switch for a small fee （first time only
                            <span style={{ color: '#FFF78C' }}>
                              <span style={{ textDecoration: 'line-through' }}>$7</span> ⇒ $2
                            </span>
                            ）
                          </>
                        )
                      }
                    </Button>
                  </>
                )
              }
              <Typography
                variant="body2"
              >
                <FormattedMessage id="character_swap.upgrade.text" />
              </Typography>
              <Button
                sx={{ marginTop: '8px !important' }}
                onClick={() => {
                  navigateTo(backToPlanPagePath)
                  TagManager.dataLayer({
                    dataLayer: {
                      event: "gtm_event",
                      ga_event: "move_plan_from_switch_button",
                      character_id: characterId,
                      user_id: user?.id,
                    }
                  });
                  closeDialog();
                }}
                fullWidth
                variant="contained"
                size="large"
              >
                <FormattedMessage id="character_swap.upgrade" />
              </Button>
            </>
          ) : (
            <>
              {isSwitchSceneTutorial && <SwitchSceneHint />}
              <Button
                disabled={disabled}
                onClick={handleSubmit}
                fullWidth
                variant="contained"
                size="large"
                sx={{ zIndex: isSwitchSceneTutorial ? 101 : 1 }}
              >
                <FormattedMessage id="character_swap.generate" />
              </Button>
              <Typography
                variant="body2"
                sx={{ marginTop: '8px !important', marginBottom: '8px !important' }}
              >
                <FormattedMessage id="character_swap.button_comment" />
              </Typography>
              {isLackPoint && (
                <>
                  <Button
                    onClick={() => {
                      closeDialog();
                      navigateTo(backToMypagePath);
                      TagManager.dataLayer({
                        dataLayer: {
                          event: "gtm_event",
                          ga_event: "move_plan_from_switch_dialog",
                          user_id: user?.id,
                        }
                      });
                    }}
                    fullWidth
                    variant="contained"
                    size="large"
                    color="tertiary"
                  >
                    <FormattedMessage id="character_swap.buy_item" />
                  </Button>
                </>
              )}
            </>
          )}

          <Button
            onClick={closeDialog}
            fullWidth
            variant="outlined"
            size="large"
          >
            キャンセル
          </Button>
        </Stack>
      </DialogContent>
    </>
  );
}
