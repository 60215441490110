import { useEffect, useState } from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import { drawerWidth } from "@/constants";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "@/services/hooks";
import { DialogTitle, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { normalizePhoneNumber, UserUpdate, validateUserName } from "@/services/models";
import { updateUser } from "@/services/actions/userActions";
import { openSnackbar } from "@/services/actions/commonActions";

interface Props {
  open: boolean;
  onClose: () => void;
}

export default function UserInfoDialog({
  open,
  onClose,
}: Props) {
  const dispatch = useDispatch();
  const [userInfo, setUserInfo] = useState<UserUpdate>({})
  const [loading, setLoading] = useState<boolean>(false);
  const { user } = useSelector(state => state.user);
  useEffect(() => {
    if (!user) {
      return;
    }
    setUserInfo({
      name: user.name,
      phone: user.phone,
      postal_code: user.postal_code,
      state: user.state,
      city: user.city,
      address: user.address,
      country: user.country || 'JP',
    })
  }, [user, open])

  const isInvalidName = !!userInfo?.name && !validateUserName(userInfo.name);

  const handleClose = loading
    ? () => null
    : () => {
      onClose();
      setLoading(false);
    }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      sx={{
        "& .MuiDialog-container": {
          sm: { marginLeft: `${drawerWidth}px` }
        },
      }}
    >
      <DialogTitle>
        <FormattedMessage id="user.info.update.title" />
      </DialogTitle>
      <DialogContent
        style={{ paddingTop: '16px', paddingBottom: '16px' }}
        sx={{
          minWidth: "300px",
          maxWidth: "345px",
        }}
      >
        <Stack
          justifyContent="center"
          alignItems="center"
          gap={2}
        >
          <TextField
            fullWidth
            required
            focused
            color="text"
            label={<FormattedMessage id="user.info.name" />}
            value={userInfo.name || ''}
            onChange={(e) => setUserInfo(v => {
              return { ...v, name: e.target.value }
            })}
            error={isInvalidName}
          />
          {isInvalidName && (
            <Typography color="error" fontSize="12px" mt={-2}>
              <FormattedMessage id="user.info.name.invalid" />
            </Typography>
          )}
          <TextField
            fullWidth
            required
            focused
            color="text"
            label={<FormattedMessage id="user.info.phone" />}
            value={userInfo.phone || ''}
            onChange={(e) => {
              const value = normalizePhoneNumber(e.target.value)
              setUserInfo(v => {
                return { ...v, phone: value }
              })
            }}
          />
          <TextField
            fullWidth
            focused
            color="text"
            label={<FormattedMessage id="user.info.postal_code" />}
            value={userInfo.postal_code || ''}
            onChange={(e) => setUserInfo(v => {
              return { ...v, postal_code: e.target.value }
            })}
            error={isInvalidName}
          />
          <TextField
            fullWidth
            focused
            color="text"
            label={<FormattedMessage id="user.info.state" />}
            value={userInfo.state || ''}
            onChange={(e) => setUserInfo(v => {
              return { ...v, state: e.target.value }
            })}
            error={isInvalidName}
          />
          <TextField
            fullWidth
            focused
            color="text"
            label={<FormattedMessage id="user.info.city" />}
            value={userInfo.city || ''}
            onChange={(e) => setUserInfo(v => {
              return { ...v, city: e.target.value }
            })}
            error={isInvalidName}
          />
          <TextField
            fullWidth
            focused
            color="text"
            label={<FormattedMessage id="user.info.address" />}
            value={userInfo.address || ''}
            onChange={(e) => setUserInfo(v => {
              return { ...v, address: e.target.value }
            })}
            error={isInvalidName}
          />
          <FormControl fullWidth required>
              <InputLabel
                shrink
                sx={{
                  backgroundColor: 'white',
                  padding: '0 4px',
                }}
              >
                <FormattedMessage id="user.info.country" />
              </InputLabel>
              <Select
                value={userInfo.country || ''}
                onChange={(e) => {
                  const value = e.target.value;
                  setUserInfo((v) => ({ ...v, country: value }));
                }}
                displayEmpty
                sx={{
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'inherit',
                  },
                }}
              >
                <MenuItem value="JP"><FormattedMessage id="user.info.country.jp" /></MenuItem>
                <MenuItem value="US"><FormattedMessage id="user.info.country.us" /></MenuItem>
                <MenuItem value="KR"><FormattedMessage id="user.info.country.kr" /></MenuItem>
                <MenuItem value="CN"><FormattedMessage id="user.info.country.cn" /></MenuItem>
                <MenuItem value="FR"><FormattedMessage id="user.info.country.fr" /></MenuItem>
                <MenuItem value="GB"><FormattedMessage id="user.info.country.gb" /></MenuItem>
              </Select>
            </FormControl>
        </Stack>
      </DialogContent>
      <DialogActions
        sx={{
          flexDirection: "row",
          px: 3,
          py: 2,
          gap: 1,
        }}
      >
        <Button
          variant="text"
          fullWidth
          disabled={loading}
          onClick={handleClose}
        >
          <FormattedMessage id="cancel" />
        </Button>
        <Button
          variant='contained'
          fullWidth
          disabled={loading || !userInfo.name || !userInfo.phone || isInvalidName}
          onClick={() => {
            const name = userInfo.name
            const phone = userInfo.phone
            const postal_code = userInfo.postal_code
            const state = userInfo.state
            const city = userInfo.city
            const address = userInfo.address
            const country = userInfo.country
            if (!name || !phone || !country) {
              return;
            }
            setLoading(true);
            dispatch(updateUser({ name, phone, postal_code, state, city, address, country}))
              .then(() => {
                handleClose();
              })
              .catch(() => {
                dispatch(openSnackbar(<FormattedMessage id="user.info.update.failed" />));
              })
              .finally(() => {
                setLoading(false);
              })
          }}
        >
          <FormattedMessage id="change" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
