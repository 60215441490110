// QAPage.tsx
import React from "react";

import {
  Stack,
  List,
  Box,
  ListItem,
  ListItemText,
  ListItemIcon,
  Avatar,
} from "@mui/material";

import AppBar from "@/components/AppBar";
import { FormattedMessage } from "react-intl";
import { isAdult } from "@/constants";

const FAQPage: React.FC = () => {
  const list = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16]
  // 1はadultでのみ表示
  if (isAdult) {
    list.unshift(1)
  }
  return (
    <AppBar title={<FormattedMessage id="faq.title" />}>
      <Stack padding={2}>
        <List>
          {list.map((num, index) => (
            <Box key={index}>
              <ListItem
                sx={{
                  backgroundColor: "#FFF8F8",
                }}
              >
                <ListItemIcon>
                  <Avatar sx={{ color: "#21005D", backgroundColor: "#FFD9E2" }}>
                    Q
                  </Avatar>
                </ListItemIcon>
                <ListItemText sx={{ whiteSpace: 'pre-wrap' }}><FormattedMessage id={`faq.q_${num}`} /></ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Avatar sx={{ color: "#21005D", backgroundColor: "#FFF" }}>
                    A
                  </Avatar>
                </ListItemIcon>
                <ListItemText sx={{ whiteSpace: 'pre-wrap' }} ><FormattedMessage id={`faq.a_${num}`} /></ListItemText>
              </ListItem>
            </Box>
          ))}
        </List>
      </Stack>
    </AppBar>
  );
};

export default FAQPage;
