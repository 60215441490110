import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import Progress from "@/components/Progress";
import { useUserContext } from '@/contexts/user';
import { FormattedMessage } from "react-intl";
import { trackingUrlParam } from "@/utils";
import ReuploadingStatusPoller from "@/components/Scene/ReuploadingStatusPoller";

type LoginLayoutProps = {
  children: React.ReactNode;
};

export const LoginLayout: React.FC<LoginLayoutProps> = ({ children }) => {
  const [timedOut, setTimedOut] = useState(false);
  const { user, loading } = useUserContext();
  const navigateTo = useNavigate();
  useEffect(() => {
    if (!user && !loading) {
      console.log("user not logged in");
      const timeoutId = setTimeout(() => {
        setTimedOut(true);
      }, 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [user, loading]);

  useEffect(() => {
    if (timedOut) {
      const queryWithHash = trackingUrlParam()
      if (window.location.pathname === '/') {
        navigateTo(`/demo${queryWithHash}`);
      } else {
        navigateTo(`/users/login${queryWithHash}`);
      }
    }
  }, [timedOut, navigateTo]);

  if (timedOut) {
    return <div><p><FormattedMessage id="login.timeout" /></p></div>;
  }

  if (loading) {
    return <div><p><FormattedMessage id="login.loading" /></p></div>;
  }

  if (!user) {
    return <div><p><FormattedMessage id="login.not_login" /></p></div>;
  }

  return (
    <>
      {children}
      <Progress />
      <ReuploadingStatusPoller />
    </>
  )
};