import SmallGenreButton from "@/components/common/SmallGenreButton";
import { useMemo } from "react";
import { Box } from "@mui/material";

interface Props {
  genres: Array<{ id: number; name: string; category: string }>;
  selectedGenres: number[];
  handleGenreClick: (id: number) => void;
}

export default function CharacterGenreList(props: Props) {
  const { genres, selectedGenres, handleGenreClick } = props;

  const genreButtons = useMemo(() => {
    return genres
      .filter((g) => ['hairstyle'].includes(g.category))
      .map((genre) => (
        <SmallGenreButton
          key={genre.id}
          label={genre.name}
          onClick={() => handleGenreClick(genre.id)}
          selected={selectedGenres.includes(genre.id)}
        />
      ));
  }, [genres, selectedGenres, handleGenreClick]);
  return (
    <Box display='flex' flexWrap='wrap' gap="6px">
      {genreButtons}
    </Box>
  );
}
