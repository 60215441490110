/**
 * フィード、フィードメディアなどを含めたシーンのコンポーネント。
 * トップページや、キャラクターページから呼び出される。
 */
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';

import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { Box, CircularProgress, Divider, IconButton, Stack } from "@mui/material";
import {
  FavoriteBorderOutlined,
  Favorite
} from '@mui/icons-material';

import { getRelativeTime } from "@/utils";
import { mainTheme } from "@/services/themes";
import { useTutorialAlmostDone, Scene, SwitchedScene } from "@/services/models";
import { ApiService } from "@/services/api";
import { openPlanDialog } from "@/services/actions/commonActions";
import { useDispatch, useSelector } from '@/services/hooks';
import { FormattedMessage } from "react-intl";
import SwitchedScenePlayer from "@/components/LightStreamPlayer/SwitchedScenePlayer";
import SceneComp from "@/components/Scene";

interface Props {
  showDivider: boolean;
  onFavoriteChanged: (sceneId: number) => void;
  scene: SwitchedScene;
  showCharacter?: boolean;
}

export default function SceneSwitchedComp({
  showDivider,
  onFavoriteChanged = () => { },
  scene,
  showCharacter = true,
}: Props) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const { user, detail } = useSelector((state) => state.user);

  const isTutorialAlmostDone = useTutorialAlmostDone();
  const isSuggestTestTarget = user?.is_free_plan
    && isTutorialAlmostDone
    && user?.id && user.id % 10 < 8 // ABテストの表示対象かどうか idの下一桁が0~7のユーザーに表示する
    && detail?.switch_trial_completed
    && false; // 一旦非表示中
  const [suggestedScenes, setSuggestedScenes] = useState<Scene[]>([]);

  useEffect(() => {
    if (!isSuggestTestTarget) {
      setSuggestedScenes([]);
      return;
    }
    ApiService.getSuggestedScene(scene.id)
      .then((data) => {
        setSuggestedScenes(data);
      })
      .catch(() => setSuggestedScenes([]));
  }, [scene.id, isSuggestTestTarget])

  const handleClickLike = (sceneId: number) => {
    setLoading(true);
    ApiService.sceneLike(sceneId)
      .then(() => {
        onFavoriteChanged(sceneId)
      }).catch((error) => {
        if (error.response.status === 403) {
          dispatch(openPlanDialog('favorite'));
        }
      }).finally(() => {
        setLoading(false);
      });
  }

  const handleClickUnlike = (sceneId: number) => {
    setLoading(true);
    ApiService.sceneUnlike(sceneId)
      .then(() => {
        onFavoriteChanged(sceneId)
      }).finally(() => {
        setLoading(false);
      });
  }

  return (
    <>
      <Card
        sx={{
          boxShadow: "none",
          position: 'relative',
        }}
      >
        {showCharacter && (
          <CardHeader
            sx={{ padding: "8px 0" }}
            avatar={
              (scene.character?.is_editable && !scene.character?.is_limited) ?
                <Link to={`/characters/${scene.character.id}`}>
                  <Avatar src={scene.character.url} />
                </Link> :
                <div>
                  <Avatar src={scene.character?.url} />
                </div>
            }
            title={scene.character?.name}
            subheader={getRelativeTime(scene.posted_at)}
            disabled={!scene.character?.is_editable}
          />
        )}

        <Typography
          variant="body2"
          color="text.primary"
          marginTop={2}
          display="flex"
          alignItems="flex-end"
          justifyContent="space-between"
          width="100%"
        >
          {scene.title}
        </Typography>

        <Swiper
          modules={[Navigation, Pagination]}
          pagination
          navigation
          style={{
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            "--swiper-navigation-size": "20px",
            "--swiper-theme-color": mainTheme.palette.primary.main,
            marginTop: '16px'
          }}
        >

          <SwiperSlide style={{ padding: 0 }}>
            <SwitchedScenePlayer scene={scene} />
          </SwiperSlide>

        </Swiper>

        <Stack spacing={{ xs: 1, sm: 2 }} direction="row" useFlexGap flexWrap="wrap" marginTop={2} sx={{ justifyContent: scene.is_favorable ? 'space-between' : 'flex-end' }}>
          {scene.is_favorable && (
            <>
              <IconButton
                size="medium"
                color="inherit"
                sx={{ "&:hover": { color: mainTheme.palette.primary.main } }}
                onClick={() => scene.is_favorited ? handleClickUnlike(scene.id) : handleClickLike(scene.id)}
              >
                {loading ? (
                  <CircularProgress size={24} color="primary" />
                ) : (
                  scene.is_favorited ? (
                    <Favorite color="primary" />
                  ) : (
                    <FavoriteBorderOutlined />
                  )
                )}
              </IconButton>
            </>
          )}
        </Stack>
      </Card>
      {suggestedScenes.length > 0 && (
        <Stack p={2} sx={{ backgroundColor: "rgba(243, 243, 243, 1)" }} mt={3}>
          <Typography
            variant='h6'
            color="primary"
            fontWeight={700}
            fontSize="18px"
          >
            <FormattedMessage id="home.suggested_scene.switched.title" />
          </Typography>
          {suggestedScenes.map((scene, index) => (
            <Box key={`${scene.id}_${index}`}>
              <SceneComp
                scene={scene}
                showDivider={index < suggestedScenes.length - 1}
                isShowGenreSearch
                sceneType="myscene"
                onFavoriteChanged={() => { }}
                showCharacter={false}
                bgColor="rgba(0, 0, 0, 0)"
                isSuggested
              />
            </Box>
          ))}
        </Stack>
      )}
      {showDivider && <Divider sx={{ mt: 2 }} />}
    </>
  );
}
