import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';

import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import WarningIcon from '@mui/icons-material/Warning';
import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemSecondaryAction,
  IconButton, Stack
} from "@mui/material";

import AppBar from "@/components/AppBar";
import { getUserDetail } from "@/services/actions/userActions";
import { useDispatch, useSelector } from '@/services/hooks';
import CurrentPlan from "@/components/common/CurrentPlan";
import { FormattedMessage } from "react-intl";
import UserInfoDialog from "@/components/Dialog/user_info";
import { openSnackbar } from "@/services/actions/commonActions.ts";

export const MyPage: React.FC = () => {
  const dispatch = useDispatch();
  const { user, detail } = useSelector((state) => state.user);
  const [open, setOpen] = useState(false)

  useEffect(() => {
    dispatch(getUserDetail());
    const urlParams = new URLSearchParams(window.location.search);
    const purchaseSuccess = urlParams.get('purchase_success');
    const isSuccess = purchaseSuccess === "true"
    if (purchaseSuccess) {
      if (isSuccess) {
        dispatch(openSnackbar(<FormattedMessage id="mypage.purchase_item.success" />));
      } else {
        dispatch(openSnackbar(<FormattedMessage id="mypage.purchase_item.failed" />));
      }
      urlParams.delete("purchase_success");
      window.history.replaceState({}, "", window.location.pathname + "?" + urlParams.toString());
    }
  }, [dispatch])

  const arrowElement = () => (
    <ListItemSecondaryAction>
      <IconButton edge="end" aria-label="arrow-right">
        <ArrowRightIcon />
      </IconButton>
    </ListItemSecondaryAction>
  );

  return (
    <AppBar title={<FormattedMessage id="mypage.title" />}>
      <div style={{ width: '100%', height: '100%', position: 'relative', background: 'white' }}>
        <List sx={{ padding: 0 }}>
          <CurrentPlan
            enableLink
            showCampaign // TODO: 決済復活キャンペーン終了したら消す
          />
          <ListItem disablePadding sx={{ backgroundColor: '#F5F5F5', borderBottom: '1px solid rgba(0, 0, 0, 0.12)', paddingTop: '24px' }}>
            <ListItem>
              <ListItemText primary={<FormattedMessage id="mypage.user_info" />} />
            </ListItem>
          </ListItem>
          {user && user.name && (
            <ListItem disablePadding sx={{ backgroundColor: 'white', borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
              <ListItemButton onClick={() => setOpen(true)}>
                <ListItemText primary={user.name} secondary={<FormattedMessage id="user.info.name" />} />
                {arrowElement()}
              </ListItemButton>
            </ListItem>
          )}
          {user && user.phone && (
            <ListItem disablePadding sx={{ backgroundColor: 'white', borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
              <ListItemButton onClick={() => setOpen(true)}>
                <ListItemText primary={user.phone} secondary={<FormattedMessage id="user.info.phone" />} />
                {arrowElement()}
              </ListItemButton>
            </ListItem>
          )}
          {user && user.postal_code && (
            <ListItem disablePadding sx={{ backgroundColor: 'white', borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
              <ListItemButton onClick={() => setOpen(true)}>
                <ListItemText primary={user.postal_code} secondary={<FormattedMessage id="user.info.postal_code" />} />
                {arrowElement()}
              </ListItemButton>
            </ListItem>
          )}
          {user && user.state && (
            <ListItem disablePadding sx={{ backgroundColor: 'white', borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
              <ListItemButton onClick={() => setOpen(true)}>
                <ListItemText primary={user.state} secondary={<FormattedMessage id="user.info.state" />} />
                {arrowElement()}
              </ListItemButton>
            </ListItem>
          )}
          {user && user.city && (
            <ListItem disablePadding sx={{ backgroundColor: 'white', borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
              <ListItemButton onClick={() => setOpen(true)}>
                <ListItemText primary={user.city} secondary={<FormattedMessage id="user.info.city" />} />
                {arrowElement()}
              </ListItemButton>
            </ListItem>
          )}
          {user && user.country && (
            <ListItem disablePadding sx={{ backgroundColor: 'white', borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
              <ListItemButton onClick={() => setOpen(true)}>
                <ListItemText primary={user.country} secondary={<FormattedMessage id="user.info.country" />} />
                {arrowElement()}
              </ListItemButton>
            </ListItem>
          )}
          {user && (
            <ListItem disablePadding sx={{ backgroundColor: 'white', borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
              {user.email ? (
                <ListItemButton>
                  <ListItemText primary={user.email} secondary={<FormattedMessage id="mypage.mail" />} />
                </ListItemButton>
              ) : (
                <ListItem disablePadding>
                  <Link to="/users/email-registration" style={{ width: '100%', textDecoration: 'none' }}>
                    <ListItemButton>
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <WarningIcon sx={{ color: "#D32F2F" }} />
                        <ListItemText
                          primary={<FormattedMessage id="mypage.register_mail" />}
                          primaryTypographyProps={{ color: "#D32F2F" }}
                          secondary={<FormattedMessage id="mypage.register_mail.text" />}
                        />
                        {arrowElement()}
                      </Stack>
                    </ListItemButton>
                  </Link>
                </ListItem>
              )}

            </ListItem>
          )}
          <ListItem disablePadding sx={{ backgroundColor: 'white', borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
            {user?.has_password ? (
              <Link to="/users/password-reset" style={{ width: '100%', textDecoration: 'none' }}>
                <ListItemButton>
                  <ListItemText
                    primary="●●●●●●●●●●"
                    secondary={<FormattedMessage id="mypage.password" />}
                  />
                  {arrowElement()}
                </ListItemButton>
              </Link>
            ) : user?.email ? (
              <Link to="/users/password-reset" style={{ width: '100%', textDecoration: 'none' }}>
                <ListItemButton>
                  <ListItemText
                    primary={<FormattedMessage id="mypage.password.none" />}
                    secondary={<FormattedMessage id="mypage.password.attention" />}
                  />
                  {arrowElement()}
                </ListItemButton>
              </Link>
            ) : (
              <ListItemButton>
                <ListItemText
                  primary={<FormattedMessage id="mypage.password.none" />}
                  secondary={<FormattedMessage id="mypage.password.attention.no_mail" />}
                />
                {arrowElement()}
              </ListItemButton>
            )}
          </ListItem>
          <ListItem disablePadding sx={{ backgroundColor: '#F5F5F5', borderBottom: '1px solid rgba(0, 0, 0, 0.12)', paddingTop: '24px' }}>
            <ListItem>
              <ListItemText primary={<FormattedMessage id="mypage.other_info" />} />
            </ListItem>
          </ListItem>
          <ListItem disablePadding sx={{ backgroundColor: 'white', borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
            <Link to="/users/plan" style={{ width: '100%', textDecoration: 'none' }}>
              <ListItemButton>
                <ListItemText
                  primary={<FormattedMessage id="mypage.current_plan" />} secondary={`${detail?.plan_name}` || 'none'}
                />
                {arrowElement()}
              </ListItemButton>
            </Link>
          </ListItem>
          <ListItem disablePadding sx={{ backgroundColor: 'white', borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
            <Link to="/users/payment-history" style={{ width: '100%', textDecoration: 'none' }}>
              <ListItemButton>
                <ListItemText primary={<FormattedMessage id="mypage.purchase_history" />} secondary={<FormattedMessage id="mypage.plan_and_point" />} />
                {arrowElement()}
              </ListItemButton>
            </Link>
          </ListItem>

          <ListItem disablePadding sx={{ backgroundColor: 'white', borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
            <Link to="/law" style={{ width: '100%', textDecoration: 'none' }}>
              <ListItemButton>
                <ListItemText primary={<FormattedMessage id="mypage.law" />} />
                {arrowElement()}
              </ListItemButton>
            </Link>
          </ListItem>
        </List>
      </div>
      <UserInfoDialog open={open} onClose={() => setOpen(false)} />
    </AppBar>
  );
};

export default MyPage;
