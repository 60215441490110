import { axios } from '@/services/api';
import { TutorialStep, UserDetail } from '@/services/models';
import Axios from 'axios';
import { Dispatch } from 'redux';

export const getUserInfo = () => async (dispatch: Dispatch) => {
  dispatch({ type: 'USER_INFO_REQUEST' });

  try {
    const { data } = await axios.get('/api/v1/users/me', {withCredentials: true});
    dispatch({ type: 'USER_INFO_SUCCESS', payload: data });
    if (data.is_rewrited_plan) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      dispatch(logout())
    }
    return data;
  } catch (e: unknown) {
    if (Axios.isAxiosError(e)) {
      dispatch({ type: 'USER_INFO_FAIL', payload: e.message });
    }
  }
};

export const getUserDetail = () => async (dispatch: Dispatch): Promise<UserDetail|null> => {
  try {
    const { data } = await axios.get('/api/v1/users/me/detail', {withCredentials: true});
    dispatch({ type: 'USER_DETAIL_SUCCESS', payload: data });
    if (data.timesale_remain_sec) {
      dispatch({ type: 'SHOW_TIMESALE_COUNTDOWN' })
    }
    return data;
  } catch (e: unknown) {
    if (Axios.isAxiosError(e)) {
      dispatch({ type: 'USER_DETAIL_FAIL', payload: e.message });
    }
    return null;
  }
};

export const loginUser = (email: string, password: string) => async (dispatch: Dispatch) => {
  try {
    const { data } = await axios.post('/api/v1/token', {
      email: email,
      password: password,
    },{ withCredentials: true });
    dispatch({ type: 'USER_INFO_SUCCESS', payload: data });
    return { success: true, data }
  } catch (e: unknown) {
    if (Axios.isAxiosError(e)) {
      dispatch({ type: 'USER_INFO_FAIL', payload: e.message });
    }
    return { success: false }
  }
};

export const getLoginBonus = () => async (dispatch: Dispatch) => {
  try {
    const {data} = await axios.get('/api/v1/login_bonus', {withCredentials: true});
    dispatch({type: 'LOGIN_BONUS_SUCCESS', payload: data.result});
    if (data.is_update || data.is_create) {
      // ここまで
      // dispatch({type: 'OPEN_LOGIN_BONUS_DIALOG'});
    }
  } catch (e: unknown) {
    if (Axios.isAxiosError(e)) {
      dispatch({type: 'LOGIN_BONUS_FAIL', payload: e.message});
    }
  }
};

export const loginGoogleUser = (email: string, socialAccountId: string) => async (dispatch: Dispatch) => {
  try {
    const { data } = await axios.post('/api/v1/token/google', {
      email: email,
      social_account_id: socialAccountId
    },{ withCredentials: true });
    dispatch({ type: 'USER_INFO_SUCCESS', payload: data });
    return { success: true, data }
  } catch (e: unknown) {
    let status;
    if (Axios.isAxiosError(e)) {
      dispatch({ type: 'USER_INFO_FAIL', payload: e.message });
      status = e.response?.status;
    }
    return { success: false, status: status }
  }
};

export const loginLineUser = (code: string) => async (dispatch: Dispatch) => {
  try {
    const { data } = await axios.post('/api/v1/token/line', {
      code: code,
    },{ withCredentials: true });
    dispatch({ type: 'USER_INFO_SUCCESS', payload: data });
    return { success: true, data }
  } catch (e: unknown) {
    let status;
    if (Axios.isAxiosError(e)) {
      dispatch({ type: 'USER_INFO_FAIL', payload: e.message });
      status = e.response?.status;
    }
    return { success: false, status: status }
  }
};

export const loginXUser = (code: string) => async (dispatch: Dispatch) => {
  try {
    const { data } = await axios.post('/api/v1/token/x', {
      code: code,
    },{ withCredentials: true });
    dispatch({ type: 'USER_INFO_SUCCESS', payload: data });
    return { success: true, data }
  } catch (e: unknown) {
    let status;
    if (Axios.isAxiosError(e)) {
      dispatch({ type: 'USER_INFO_FAIL', payload: e.message });
      status = e.response?.status;
    }
    return { success: false, status: status }
  }
};

export const logout = () => async (dispatch: Dispatch) => {
  try {
    await axios.post('/api/v1/logout', {
    },{ withCredentials: true });
    dispatch({ type: 'USER_LOGOUT' });
  } catch (e: unknown) {
    // logout のサーバー通信が失敗
    // if (axios.isAxiosError(e)) {
    // dispatch({ type: 'USER_DETAILS_FAIL', payload: error.message });
    // }
  }
};

export const updateUser = (
  { name, phone, address, city, state, country, postal_code }:
    { name: string,
      phone: string,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      country?: string | null,
      postal_code?: string | null,
    }) => async (dispatch: Dispatch) => {
  // ハイフン・スペースを削除
  const replacedPhone = phone.replace(/[- ]/g, '');
  const response = await axios.patch('/api/v1/users/me', {
    name,
    phone: replacedPhone,
    address,
    city,
    state,
    country,
    postal_code,
  }, {
    withCredentials: true,
  });
  dispatch({ type: 'USER_UPDATE_SUCCESS', payload: response.data });
  return response.data;
}

export const completeTutorialStep = ({ tutorialStep }: { tutorialStep: TutorialStep }) => async (dispatch: Dispatch) => {
  const response = await axios.post(
    `/api/v1/users/me/tutorial_step/complete`,
    { step: tutorialStep},
    { withCredentials: true }
  );
  dispatch({ type: 'USER_TUTORIAL_STEP_SUCCESS', payload: response.data });
  return response.data;
}
