import AppBar from "@/components/AppBar";
import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import XIcon from '@mui/icons-material/X';
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useSelector } from "@/services/hooks";

const Invitation: React.FC = () => {
  const { user } = useSelector((state) => state.user);
  const linkUrl = `${import.meta.env.VITE_SITE_URL}?invitation=${user?.invitation_code}`;
  const handleShareToX = () => {
    const text = encodeURIComponent(
      `無料登録で、今だけ1回分の顔交換が無料に！\n\n${linkUrl}\n\n知り合いの顔写真で試してみよう！\n\n#FACESWITCH #自分だけのAVを作れるAI`
    );
    const twitterShareUrl = `https://twitter.com/intent/tweet?text=${text}`;

    window.open(twitterShareUrl, "_blank", "noopener,noreferrer");
  };

  // クリップボードにコピーする関数
  const handleCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(linkUrl);
    } catch (err) {
      console.error("コピーに失敗しました", err);
    }
  };

  return (
    <AppBar title={<FormattedMessage id="invitation.title" />}>
      <Stack
        paddingX={2}
        paddingY="22px"
        gap={2}
        mb={5}
      >
        <Stack>
          <Box>
            <img src="/invitation/invitation@2x.webp" srcSet={"/invitation/invitation@2x.webp 2x, /invitation/invitation@3x.webp 3x"} alt="友達招待" style={{ width: "100%", display: 'block' }} />
          </Box>
          <Typography
            textAlign="center"
            sx={{
              backgroundColor: '#000000',
              color: '#FFFFFF',
              fontSize: '12px',
              height: '27px',
              lineHeight: '27px',
            }}
          >
            対象期間：2025年
            <span style={{ fontSize: '16px' }}>4月1日</span>
            まで
          </Typography>
        </Stack>

        <Stack
          mt="-2px"
        >
          <Box>
            <img src="/invitation/description@2x.png" srcSet={"/invitation/description@2x.png 2x, /invitation/description@3x.png 3x"} alt="友達招待説明" style={{ width: "100%", display: 'block' }} />
          </Box>
        </Stack>

        <Stack>
          <TextField
            size="small"
            value={linkUrl}
            aria-readonly
            sx={{
              backgroundColor: '#F2F2F2',
            }}
          />
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          gap={'11px'}
        >
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#000000',
              width: '100%',
              maxWidth: '166px',
              '&:hover': {
                backgroundColor: '#313131',
              }
            }}
            onClick={handleShareToX}
            startIcon={<XIcon />}
          >
            共有
          </Button>

          <Button
            variant="contained"
            color="primary"
            sx={{
              width: '100%',
              maxWidth: '166px',
            }}
            onClick={handleCopyLink}
            startIcon={<ContentCopyIcon />}
          >
            URLコピー
          </Button>
        </Stack>

        <Box>
          <img src="/invitation/how_to_invitation@2x.png" srcSet={"/invitation/how_to_invitation@2x.png 2x, /invitation/how_to_invitation@3x.png 3x"} alt="招待方法" style={{ width: "100%", display: 'block' }} />
        </Box>

        <Box>
          <img src="/invitation/tips@2x.png" srcSet={"/invitation/tips@2x.png 2x, /invitation/tips@3x.png 3x"} alt="招待のコツ" style={{ width: "100%", display: 'block' }} />
        </Box>

        <Box>
          <img src="/invitation/warning@2x.png" srcSet={"/invitation/warning@2x.png 2x, /invitation/warning@3x.png 3x"} alt="注意事項" style={{ width: "100%", display: 'block' }} />
        </Box>
      </Stack>
    </AppBar>
  )
}

export default Invitation;
