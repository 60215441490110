import SceneComp from "@/components/Scene";
import { ApiService } from "@/services/api";
import { useDispatch, useSelector, useUrlParams } from "@/services/hooks";
import { useTutorialAlmostDone, Scene } from "@/services/models";
import { Box, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";


const SuggestedScenes = () => {
  const completeToggle = useSelector((state) => state.common.progress.complete_toggle);
  const { user, detail } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { getUrlParam } = useUrlParams();
  const hasFilter = !!getUrlParam('filter');

  const isTutorialAlmostDone = useTutorialAlmostDone();
  const isSuggestTestTarget = user?.is_free_plan
    && isTutorialAlmostDone
    && user?.id // && [1, 2, 6, 7, 8].includes(user.id % 10) // ABテストの表示対象かどうか -> 全部出す対応に変更
    && detail?.switch_trial_completed;
  const [suggestedScenes, setSuggestedScenes] = useState<Scene[]>([]);

  useEffect(() => {
    if (!isSuggestTestTarget) {
      setSuggestedScenes([]);
      dispatch({ type: 'HAS_NO_SUGGESTED_SCENE' });
      return;
    }
    ApiService.getSuggestedScenes()
      .then((data) => {
        setSuggestedScenes(data);
        if (data.length > 0) {
          dispatch({ type: 'HAS_SUGGESTED_SCENE' });
        } else {
          dispatch({ type: 'HAS_NO_SUGGESTED_SCENE' });
        }
      })
      .catch(() => setSuggestedScenes([]));
  }, [dispatch, completeToggle, isSuggestTestTarget]);

  return suggestedScenes.length > 0 && !hasFilter && (
    <Stack p={2} sx={{ backgroundColor: "rgba(243, 243, 243, 1)" }} mb={3}>
      <Typography
        variant='h6'
        color="primary"
        fontWeight={700}
        fontSize="18px"
      >
        <FormattedMessage id="home.suggested_scene.original.title" />
      </Typography>
      {suggestedScenes.map((scene, index) => (
        <Box key={`${scene.id}_${index}`}>
          <SceneComp
            scene={scene}
            showDivider={index < suggestedScenes.length - 1}
            isShowGenreSearch
            sceneType="myscene"
            onFavoriteChanged={() => { }}
            showCharacter={false}
            bgColor="rgba(0, 0, 0, 0)"
            isSuggested
          />
        </Box>
      ))}
    </Stack>
  )
}

export default SuggestedScenes;