import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { drawerWidth } from '@/constants';
import { FormattedMessage } from 'react-intl';
import { useEffect, useMemo, useState } from 'react';
import { Genre } from '@/services/models';
import _ from 'lodash';
import { Box, Button, ButtonBase, Checkbox, DialogActions, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import { useUrlParams } from '@/services/hooks';
import { resolveGenreParam } from '@/utils';
import SmallGenreButton from '@/components/common/SmallGenreButton';
import { ApiService } from '@/services/api';
import { color } from '@/services/themes';

interface Props {
  open: boolean;
  onClose: () => void;
  genre_list: Genre[];
}

export default function GenreDialog(props: Props) {
  const { setUrlParam, getUrlParam } = useUrlParams();

  const onClose = props.onClose || function () { };

  const [pageCategory, setPageCategory] = useState('default');
  const [selectedFilter, setSelectedFilter] = useState<string>('');
  const [sceneCount, setSceneCount] = useState<{ count: number, genre_ids: number[] }>({ count: 0, genre_ids: [] });


  const clear = () => {
    setSelectedFilter('');
    setPageCategory('default');
  };

  useEffect(() => {
    if (props.open) {
      clear();
      setSelectedFilter(getUrlParam('filter') || '')
    }
  }, [props.open]);

  useEffect(() => {
    if (props.open) {
      ApiService.getOriginalSceneCount({ filter: selectedFilter }).then((d) => {
        setSceneCount(d)
      }).catch(() => {
        setSceneCount({ count: 0, genre_ids: [] })
      })
    }
  }, [selectedFilter, props.open]);

  const genreCategories = useMemo(() => {
    const genreList: { category: string, list: Genre[] }[] = []
    _.each(props.genre_list, (genre) => {
      const item = _.find(genreList, { category: genre.category });
      if (item) {
        item.list.push(genre);
      } else {
        genreList.push({
          category: genre.category,
          list: [genre],
        });
      }
    });
    const newGenreList = [...genreList];
    newGenreList.push({
      category: 'publish_date',
      list: [{
        id: -1,
        name: '新作',
        order: 1,
        category: 'publish_date',
      }, {
        id: -2,
        name: '準新作',
        order: 1,
        category: 'publish_date',
      }],
    });
    const rankingList = [{
      id: -3,
      name: '週間ランキング',
      order: 1,
      category: 'ranking',
    }, {
      id: -4,
      name: '累計ランキング',
      order: 1,
      category: 'ranking',
    }];

    const rankingCategory = _.find(newGenreList, { category: 'ranking' });
    if (rankingCategory) {
      rankingCategory.list.push(...rankingList);
    } else {
      newGenreList.push({
        category: 'ranking',
        list: rankingList,
      });
    }
    return newGenreList;
  }, [props.genre_list])

  const addGenreList = useMemo(() => {
    const list = ['フェラ', '普通（ボブ）', '巨乳', '黒髪',
      '細身', '主観', '新作', '美少女', 'ハメ撮り']
    const result: Genre[] = [];
    _.each(props.genre_list, (genre) => {
      if (_.includes(list, genre.name)) {
        result.push(genre);
      }
    });
    return result;
  }, [props.genre_list]);

  const renderDefaultContent = () => {
    return (
      <DialogContent
        sx={{ padding: 0 }}
      >
        <Box
          display="flex"
          flexWrap="wrap"
          width="100%"
        >
          {
            genreCategories.map((item) => {
              return (
                <ButtonBase
                  key={item.category}
                  sx={{
                    width: '100%',
                    borderBottom: '1px solid #0000001F',
                    borderRight: '1px solid #0000001F',
                  }}
                  onClick={() => {
                    setPageCategory(item.category);
                  }}
                >
                  <Box
                    width="100%"
                    position="relative"
                    sx={{
                      padding: '12.5px 16px',
                    }}
                  >
                    <Box
                      textAlign="left"
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px',
                      }}
                    >
                      <Typography variant="body1">
                        <FormattedMessage id={`category.${item.category}`} />
                      </Typography>
                      {
                        _.includes(['female_category', 'hairstyle', 'ranking'], item.category) && <RecommendTag />
                      }
                    </Box>
                    <IconButton
                      sx={{
                        position: "absolute",
                        right: '8px',
                        top: 'calc(50% - 14px)',
                      }}
                    >
                      <ArrowForwardIosIcon
                        sx={{
                          fontSize: '12px',
                        }}
                      />
                    </IconButton>
                  </Box>
                </ButtonBase>
              );
            })
          }

          {addGenreList.length > 0 && (
            <Box
              paddingY={3}
              paddingX={2}
            >
              <Typography
                variant="body1"
                textAlign="left"
              >
                <FormattedMessage id="trending_tags" />
              </Typography>
              <Box
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="start"
                gap={1}
                flexWrap="wrap"
                marginTop="12px"
              >
                {
                  addGenreList.map((genre) => {
                    const disabled = genre?.id > 0 && !_.includes(sceneCount.genre_ids, genre?.id);
                    const isChecked = selectedFilter.split(',').indexOf(genre.name) !== -1;
                    return (
                      <SmallGenreButton
                        key={genre.id}
                        disabled={disabled}
                        selected={isChecked}
                        label={genre.name}
                        onClick={() => {
                          if (disabled) { return; }
                          setSelectedFilter(before => {
                            return resolveGenreParam(before, genre.name);
                          })
                        }}
                      />
                    );
                  })
                }
              </Box>
            </Box>
          )}
        </Box>
      </DialogContent>
    )
  }

  const renderGenreContent = () => {
    const list = _.get(_.find(genreCategories, { category: pageCategory }), 'list') || [];
    return (
      <DialogContent
        sx={{ padding: 0 }}
      >
        <Box
          display="flex"
          flexWrap="wrap"
          width="100%"
        >
          {
            list.map((genre) => {
              const disabled = genre?.id > 0 && !_.includes(sceneCount.genre_ids, genre?.id);
              const isChecked = selectedFilter.split(',').indexOf(genre.name) !== -1;
              return (
                <ButtonBase
                  disabled={disabled}
                  key={genre.id}
                  sx={{
                    width: '100%',
                    borderBottom: '1px solid #0000001F',
                    borderRight: '1px solid #0000001F',
                    background: disabled ? '#0000001F' : 'transparent',
                  }}
                  onClick={() => {
                    if (disabled) { return; }
                    setSelectedFilter(before => {
                      return resolveGenreParam(before, genre.name);
                    })
                  }}
                >
                  <Box
                    width="100%"
                    position="relative"
                    display="flex"
                    justifyContent="start"
                    alignItems="center"
                    sx={{
                      padding: '12.5px 16px',
                    }}
                  >
                    <Typography
                      variant="body1"
                      textAlign="left"
                      color={disabled ? '#00000061' : 'textPrimary'}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px',
                      }}
                    >
                      {genre.name}
                      {
                        _.includes(['普通（ボブ）', '黒髪', '茶髪（明）', 'ロング', '巨乳', '細身', '美少女', 'ギャル', '週間ランキング', '累計ランキング'], genre.name) && <RecommendTag />
                      }
                    </Typography>
                    <Checkbox
                      checked={isChecked}
                      sx={{
                        position: "absolute",
                        right: '8px',
                        top: 'calc(50% - 21px)',
                        color: disabled ? 'rgba(0, 0, 0, 0.38)' : 'rgba(0, 0, 0, 0.6)',
                      }}
                    />
                  </Box>
                </ButtonBase>
              );
            })
          }
        </Box>
      </DialogContent>
    )
  }

  return (
    <Dialog
      open={props.open}
      keepMounted
      onClose={onClose}
      sx={{
        "& .MuiDialog-paper": {
          width: '100%',
          maxWidth: '375px',
          position: 'fixed',
          bottom: 0,
          margin: 0,
          height: '80%',
          borderBottomRightRadius: 0,
          borderBottomLeftRadius: 0,
        },
        "& .MuiDialog-container": {
          alignItems: "flex-start",
          sm: { marginLeft: `${drawerWidth}px` }
        },
      }}
    >
      <DialogTitle
        display="flex"
        justifyContent="center"
        position="relative"
        sx={{
          borderBottom: '1px solid #0000001F',
        }}
      >
        <Typography variant="subtitle1">{pageCategory === 'default' ? '絞り込み' : <FormattedMessage id={`category.${pageCategory}`} />}</Typography>
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            top: '8px',
            right: '8px',
          }}
        >
          <CloseIcon />
        </IconButton>
        {pageCategory !== 'default' &&
          <IconButton
            onClick={() => {
              setPageCategory('default')
            }}
            sx={{
              position: 'absolute',
              top: '16px',
              left: '16px',
            }}
          >
            <ArrowBackIos
              sx={{
                fontSize: '12px',
              }}
            />
          </IconButton>
        }
      </DialogTitle>
      {
        pageCategory === 'default' ? renderDefaultContent() : renderGenreContent()
      }

      <DialogActions
        sx={{
          padding: '16px 16px 24px',
          backgroundColor: 'rgba(0,0,0,0.12)',
          flexDirection: 'column',
          gap: "10px",
        }}>
        <Box
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="start"
          gap={1}
          flexWrap="wrap"
        >
          {
            _.map(_.filter(selectedFilter.split(',')), (label) => (
              <SmallGenreButton
                key={label}
                label={label}
                onClick={() => { }}
              />
            ))
          }
        </Box>
        <Box
          width="100%"
          display="flex"
          alignItems="stretch"
          justifyContent="center"
          gap="10px"
        >
          <Button
            size="large"
            variant="outlined"
            color="primary"
            sx={{
              backgroundColor: '#FFFFFF',
              minWidth: 'fit-content',
            }}
            onClick={() => {
              if (pageCategory === 'default') {
                clear();
              } else {
                setPageCategory('default');
              }
            }}
          >
            <Typography
              color="primary"
              fontSize="12px"
            >
              {pageCategory === 'default' ? 'クリア' : <>選択して<br />戻る</>}
            </Typography>
          </Button>
          <Button
            size="large"
            variant="contained"
            color="primary"
            sx={{
              minWidth: 'fit-content',
              paddingY: "8px",
              flex: 1,
            }}
            disabled={!sceneCount.count}
            onClick={() => {
              setUrlParam('filter', selectedFilter);
              onClose();
              setPageCategory('default')
            }}
          >
            <Typography
              color="#FFFFFF"
              fontSize="12px"
            >
              {sceneCount.count}件<br />この条件で検索する
            </Typography>
          </Button>
        </Box>
      </DialogActions>
    </Dialog >
  );
}

const RecommendTag = () => {
  return (
    <Box
      sx={{
        border: 'solid 1px',
        borderColor: color.main,
        display: 'inline-block',
        borderRadius: '4px',
      }}
    >
      <Typography
        color="primary"
        sx={{
          fontSize: '14px',
          lineHeight: 1,
          padding: '2.1px 5.6px 3.5px',
        }}
      >
        <FormattedMessage id="recommend" />
      </Typography>
    </Box>
  )
}