import { Box, Button, Divider, List, ListItem, Stack, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from "react";
import _ from 'lodash';
import AppBar from "@/components/AppBar";
import { useSelector } from '@/services/hooks';
import CurrentPlan from '@/components/common/CurrentPlan';
import { createPurchaseTemporaryId, Item, Plan, validateUserName } from '@/services/models';
import { ApiService } from "@/services/api";
import { getLocale, loadUTC } from '@/utils';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from "@/services/hooks";
import PlanDowngradeDialog from "@/components/Dialog/plan_downgrade";
import { openSnackbar, openWebpayInfoInputDialog } from '@/services/actions/commonActions';
import { getUserDetail, getUserInfo } from '@/services/actions/userActions';
import PlanFeatureDescription from '@/components/common/Plan Feature Description';
import AboutSwitchedScene from '@/components/common/AboutSwitchedScene';
import NormalItemContent from '@/components/MyPage/NormalItemContent';
import TagManager from 'react-gtm-module';

// const FREE_PLAN_ID = 1;
const BRONZE_PLAN_ID = 2;

type propType = {
  plans: Plan[];
};

const UserPlan = (props: propType) => {
  const { plans } = props;
  const { user } = useSelector((state) => state.user);
  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  const { detail } = useSelector((state) => state.user);
  const [isOpenDowngradeDialog, setIsOpenDowngradeDialog] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [items, setItems] = useState<Item[]>([]);
  const locale = getLocale()
  const normalItems = useMemo(() => {
    return _.filter(items, (item) => {
      return !item.purchase_limit && !!item.price;
    })
  }, [items]);

  const bronzePlan = useMemo(() => {
    return _.find(plans, { id: BRONZE_PLAN_ID });
  }, [plans]);

  const bronzePrice = locale === 'ja' ? bronzePlan?.price || '0' : bronzePlan?.foreign_price || '0';

  useEffect(() => {
    ApiService.fetchItems().then((data) => {
      setItems(data);
    })
  }, [])

  const handleCancel = async (text: string) => {
    if (loading) {
      return;
    }

    setLoading(true);
    ApiService.purchase_resign({ text })
      .then((data) => {
        if (data.success) {
          dispatch(openSnackbar(<FormattedMessage id="current_plan.change_plan_success" />));
          dispatch(getUserDetail());
          dispatch(getUserInfo());
          const remainPoint = (detail?.swap?.plan_remain ?? 0) + (detail?.swap?.item_remain ?? 0);
          TagManager.dataLayer({
            dataLayer: {
              event: "gtm_event",
              ga_event: "downgrade_remain_point",
              user_id: user?.id,
              remain_point: remainPoint,
            }
          });
          navigateTo('/users/plan');
        } else {
          console.error("resign response is false.");
          dispatch(openSnackbar(<FormattedMessage id="current_plan.change_plan_failed" />));
        }
      })
      .catch((error) => {
        console.error("resign error: ", error);
        dispatch(openSnackbar(<FormattedMessage id="current_plan.change_plan_failed" />));
      });
    setLoading(false);
  }

  const handlePlanContinue = async () => {
    if (!bronzePlan?.id || !user?.id) {
      return
    }

    if (user?.email === null) {
      dispatch(openSnackbar(<FormattedMessage id="mypage.register_mail.attention" />));
      return;
    }

    TagManager.dataLayer({
      dataLayer: {
        event: "gtm_event",
        ga_event: "click_buy_plan_button",
        user_id: user.id,
      }
    });

    try {
      const temporaryId = createPurchaseTemporaryId();

      if (!user.name || !user.phone || !user.city || !user.state ||
        !user.country || !user.postal_code || !validateUserName(user.name)) {
        dispatch(openWebpayInfoInputDialog({ plan_id: bronzePlan.id }));
        return;
      }
      const response = await ApiService.submitFormToWebpay({
        item_id: null,
        plan_id: bronzePlan.id,
        is_jp: locale === 'ja',
        temporary_id: temporaryId,
      })
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = response;
      document.body.appendChild(tempDiv);
      const form = tempDiv.querySelector("form");
      if (form) {
        form.submit();
      }
    } catch (e) {
      console.log(e);
    }

  };

  const day = locale === 'ja' ? loadUTC(detail?.paid_expired_at).format('YYYY年M月D日') : loadUTC(detail?.paid_expired_at).format('DD/MM/YYYY')

  return (
    <AppBar title={<FormattedMessage id="user.plan.title" />}>
      <div style={{ width: '100%', height: '100%', position: 'relative', background: 'white' }}>
        <List sx={{ padding: 0 }}>
          <CurrentPlan />

          {
            !detail?.is_continue_paid && (
              <Stack gap={1}>
                <Typography variant="body2">
                  <FormattedMessage id="user.plan.bronze.downgrade.info" />
                </Typography>
                <Box
                  sx={{
                    background: '#00000014',
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    color="primary"
                    sx={{
                      lineHeight: '36px',
                      textAlign: 'center',
                    }}
                  >
                    <FormattedMessage id="user.plan.bronze.limit_time" />：{loadUTC(detail?.paid_expired_at).format('YYYY年M月D日')}(あと{loadUTC(detail?.paid_expired_at).diff(loadUTC(''), 'day')}<FormattedMessage id="user.plan.bronze.day" />)
                  </Typography>
                </Box>
              </Stack>
            )
          }
          {
            detail?.next_update_date && (
              <Typography variant="body2">
                <FormattedMessage id="user.plan.bronze.update_time" />：{loadUTC(detail?.next_update_date).format('YYYY年M月D日')}(あと{loadUTC(detail?.next_update_date).diff(loadUTC(''), 'day')}<FormattedMessage id="user.plan.bronze.day" />)
              </Typography>
            )
          }
          {
            bronzePlan && !detail?.is_continue_paid && !detail?.next_update_date && (
              <Stack gap={1}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  sx={{
                    marginTop: '8px !important',
                  }}
                  onClick={handlePlanContinue}
                >
                  {bronzePlan.name}<FormattedMessage id="user.plan.continue_plan" /> - ${bronzePrice.toLocaleString()}<FormattedMessage id="user.plan.per_month" />
                </Button>
                <Typography variant="body2">
                  <FormattedMessage id="user.plan.continue_plan.warning" values={{ day: day }} />
                </Typography>
              </Stack>
            )
          }

          <NormalItemContent
            items={normalItems}
          />
          <Divider />

          <ListItem
            sx={{
              display: 'flex',
              padding: '24px 16px 0',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: '0',
              alignSelf: 'stretch',
            }}
          >
            <AboutSwitchedScene />

            <PlanFeatureDescription
              plans={plans}
            />

            <Stack
              width="100%"
              marginTop={3}
              marginBottom={3}
              display="flex"
              gap={1}
            >
              <Button
                size="large"
                variant="outlined"
                sx={{
                  position: 'relative',
                  width: '100%',
                  borderRadius: 'var(--borderRadius, 4px)',
                }}
                onClick={() => { setIsOpenDowngradeDialog(true); }}
              >
                <FormattedMessage id="current_plan.downgrade_plan" />
                {
                  !detail?.is_continue_paid && (
                    <Box
                      position="absolute"
                      sx={{
                        width: '100%',
                        height: '100%',
                        top: 0,
                        left: 0,
                        background: '#0000008F',
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        color="white"
                        sx={{
                          lineHeight: '40px',
                        }}
                      >
                        <FormattedMessage id="user.plan.bronze.downgraded" />
                      </Typography>
                    </Box>
                  )
                }
              </Button>

              {
                !detail?.is_continue_paid && (
                  <>
                    <Typography variant="body2">
                      <FormattedMessage id="user.plan.bronze.downgrade.info" />
                    </Typography>
                    <Box
                      sx={{
                        background: '#00000014',
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        color="primary"
                        sx={{
                          lineHeight: '36px',
                          textAlign: 'center',
                        }}
                      >
                        <FormattedMessage id="user.plan.bronze.limit_time" />：{loadUTC(detail?.paid_expired_at).format('YYYY年M月D日')}(あと{loadUTC(detail?.paid_expired_at).diff(loadUTC(''), 'day')}<FormattedMessage id="user.plan.bronze.day" />)
                      </Typography>
                    </Box>
                  </>
                )
              }
              {
                detail?.next_update_date && (
                  <Typography variant="body2">
                    <FormattedMessage id="user.plan.bronze.update_time" />：{loadUTC(detail?.next_update_date).format('YYYY年M月D日')}(あと{loadUTC(detail?.next_update_date).diff(loadUTC(''), 'day')}<FormattedMessage id="user.plan.bronze.day" />)
                  </Typography>
                )
              }
              {
                bronzePlan && !detail?.is_continue_paid && !detail?.next_update_date && (
                  <Stack gap={1}>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      sx={{
                        marginTop: '8px !important',
                      }}
                      onClick={handlePlanContinue}
                    >
                      {bronzePlan.name}<FormattedMessage id="user.plan.continue_plan" /> - ${bronzePrice.toLocaleString()}<FormattedMessage id="user.plan.per_month" />
                    </Button>
                    <Typography variant="body2">
                      <FormattedMessage id="user.plan.continue_plan.warning" values={{ day: loadUTC(detail?.paid_expired_at).format('YYYY年M月D日') }} />
                    </Typography>
                  </Stack>
                )
              }
            </Stack>

          </ListItem>
        </List >
      </div >

      <PlanDowngradeDialog
        open={isOpenDowngradeDialog}
        onExecute={async (body: string) => {
          await handleCancel(body);
          setIsOpenDowngradeDialog(false);
        }}
        onCancel={() => {
          setIsOpenDowngradeDialog(false);
        }}
      />
    </AppBar>
  );
};

export default UserPlan;
