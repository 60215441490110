import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { cfCustomerCode } from "@/constants"; // cfCustomerCode をインポート

interface DummyPlayerProps {
  src: string; // Cloudflare Streamのビデオソース
  thumbnail_start_sec?: number;
  onClick?: () => void;
}

const DummyPlayer: React.FC<DummyPlayerProps> = ({
  src,
  thumbnail_start_sec = 0,
  onClick,
}) => {
  const [aspectRatio, setAspectRatio] = useState<number>(16 / 9);
  const imgSrc = `https://customer-${cfCustomerCode}.cloudflarestream.com/${src}/thumbnails/thumbnail.jpg?time=${thumbnail_start_sec}s`;

  useEffect(() => {
    const img = new Image();
    img.onload = () => setAspectRatio(img.naturalWidth / img.naturalHeight);
    img.src = imgSrc;
  }, [imgSrc]);

  const commonStyles = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  };

  const iconContainerStyles = {
    ...commonStyles,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  };

  return (
    <Box sx={{
      position: 'relative',
      width: '100%',
      paddingTop: `${(1 / aspectRatio) * 100}%`,
      '& > img, & > div': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
      },
    }}>
      <img
        src={imgSrc}
        alt="Description"
        onClick={onClick}
      />
      <Box sx={iconContainerStyles} onClick={onClick}>
        <PlayCircleOutlineIcon sx={{ fontSize: 64, color: 'white' }} />
      </Box>
    </Box>
  );
};

export default DummyPlayer;
