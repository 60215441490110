import {
  Dialog,
} from '@mui/material';
import _ from 'lodash';

// import { Item } from '@/services/models';
import { useDispatch, useSelector } from '@/services/hooks';
import { closeCharacterSwapDialog } from '@/services/actions/commonActions';
import { useEffect } from 'react';
import { getUserDetail } from '@/services/actions/userActions';
import { drawerWidth } from '@/constants';
import { getCharacters } from '@/services/actions/characterActions';
import LackPoint from '@/components/Dialog/character_swap/LackPoint';
import Normal from '@/components/Dialog/character_swap/Normal';

export default function CharacterSwapDialog() {
  const dispatch = useDispatch();
  const { detail } = useSelector((state) => state.user);
  const remain = _.get(detail, 'swap.item_remain', 0) + _.get(detail, 'swap.plan_remain', 0)
  const { character_swap_dialog } = useSelector((state) => state.common);
  const usePoint = character_swap_dialog.point;
  const isLackPoint = remain < usePoint;

  // const [items, setItems] = useState<Item[]>([]);

  // const isShowItemBanner = useMemo(() => {
  //   return _.some(items, (item) => {
  //     return item.purchase_limit === 1 && item.price === '2.00';
  //   })
  // }, [items])

  // useEffect(() => {
  //   ApiService.fetchItems().then((data) => {
  //     setItems(data);
  //   })
  // }, [user])

  const closeDialog = () => {
    dispatch(closeCharacterSwapDialog());
  };

  useEffect(() => {
    if (!character_swap_dialog.open) {
      return;
    }
    dispatch(getUserDetail())
    dispatch(getCharacters())
  }, [character_swap_dialog.open, dispatch]);


  return (
    <Dialog
      onClose={closeDialog}
      open={character_swap_dialog.open}
      sx={{
        "& .MuiDialog-paper": {
          width: '100%',
          margin: '16px',
          padding: '24px 16px',
          overflow: 'visible',
        },
        "& .MuiDialog-container": {
          sm: { marginLeft: `${drawerWidth}px` }
        },
      }}
    >
      {isLackPoint ? <LackPoint /> : <Normal />}
    </Dialog >
  );
}


