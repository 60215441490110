import React, { useState, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import { Stream, StreamPlayerApi } from '@cloudflare/stream-react';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import CircularProgress from '@mui/material/CircularProgress';
import TagManager from "react-gtm-module";
import { useDispatch, useSelector } from "@/services/hooks.ts"; // GTMをインポート
import { Button, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { incrementSwitchedScenesCount } from '@/services/actions/sceneActions';
import { SwitchedScene } from '@/services/models';
import { Cloud } from '@mui/icons-material';
import ReactDOMServer from 'react-dom/server';
import { ApiService } from '@/services/api';
import { backToPlanPagePath } from '@/constants';
import {
  // openSwitchedFeedbackDialog,
  setReuploadingPolling
} from '@/services/actions/commonActions';
import Duration from '@/components/LightStreamPlayer/Duration';
import FindMoreScenesDialog from '@/components/common/Tutorial/FindMoreScenesDialog';
import Anchor from "@/assets/Anchor.svg";

// import { FEEDBACK_THRESHOLD_SECONDS } from '@/settings';

export interface SwitchedScenePlayerProps {
  scene: SwitchedScene;
}

const scene_type = 'switched';

const commonStyles = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
};

const iconContainerStyles = {
  ...commonStyles,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
};

const CloudIcon = ({ show, isAnimation }: { show: boolean, isAnimation?: boolean }) => {
  const [dotCount, setDotCount] = useState(0);
  useEffect(() => {
    if (!show || !isAnimation) {
      return;
    }
    const interval = setInterval(() => {
      setDotCount(v => (v + 1) % 4);
    }, 500);
    return () => clearInterval(interval);
  }, [show && isAnimation]);
  if (!show) {
    return null;
  }
  return (
    <Box sx={{
      position: 'absolute',
      top: '4px',
      right: '8px',
      color: 'white',
    }}>
      <Cloud fontSize='large' sx={{
        filter: 'drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.5))',
        position: 'absolute',
        left: 0,
        top: 0,
        opacity: 0.6,
      }} />
      <Box sx={{
        width: '35px',
        height: '35px',
        maskImage: `url('data:image/svg+xml,${ReactDOMServer.renderToString(<Cloud xmlns="http://www.w3.org/2000/svg" />)}')`,
        maskSize: 'contain',
        maskRepeat: 'no-repeat',
        maskPosition: 'center',
        opacity: 0.6,
      }}>
        <Stack gap="20px" sx={{
          opacity: 0.5,
          position: 'absolute',
          pt: "13px",
          left: "calc(50% - 6.5px)",
          animation: isAnimation ? 'cloud 1.7s infinite' : 'none',
          animationTimingFunction: 'linear',
          '@keyframes cloud': {
            '0%': { transform: 'translateY(-32px)' },
            '100%': { transform: 'translateY(0px)' },
          },
        }}>
          <img src={Anchor} alt="anchor" height="12px" />
          <img src={Anchor} alt="anchor" height="12px" />
        </Stack>
      </Box>
      {isAnimation &&
        <Typography color="white" width="max-content" fontSize="12px" fontWeight={700} sx={{
          position: 'absolute',
          bottom: '8px',
          right: '40px',
          letterSpacing: 0,
          textShadow: '1px 1px 3px rgba(0, 0, 0, 0.5)',
        }}>
          <FormattedMessage id="video_stream.reuploading" />
          <Box display="inline-block" width="12px">
            {Array(dotCount).fill('.').join('')}
          </Box>
        </Typography>
      }
    </Box>
  )
}

const SwitchedScenePlayer: React.FC<SwitchedScenePlayerProps> = ({ scene }) => {
  const dispatch = useDispatch();
  const navigateTo = useNavigate();
  // const userDetail = useSelector((state) => state.user.detail);
  const { open } = useSelector((state) => state.common.switched_feedback_dialog);
  const [streamLoaded, setStreamLoaded] = useState(false);
  const [playClicked, setPlayClicked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [aspectRatio, setAspectRatio] = useState<number>(16 / 9);
  const streamRef = useRef<StreamPlayerApi | undefined>();
  const { user } = useSelector((state) => state.user);
  const [startTime, setStartTime] = useState<number | undefined>()
  const [isPlayed, setIsPlayed] = useState(false);
  const statuses = useSelector((state) => state.common.reuploading.statuses);
  const [isReuploading, setIsReuploading] = useState(false)
  const [uid, setUid] = useState(scene.uid)
  // const [isFeedbackCompleted, setIsFeedbackCompleted] = useState(scene.is_feedback_complete)
  const isFindMoreScenesTutorial = user?.tutorial_step === 'find_more_scenes';
  const [openTutorialDialog, setOpenTutorialDialog] = useState(false);

  useEffect(() => {
    if (!scene.thumbnail_url) {
      return;
    }
    const img = new Image();
    img.onload = () => setAspectRatio(img.naturalWidth / img.naturalHeight);
    img.src = scene.thumbnail_url;
  }, [scene.thumbnail_url]);

  useEffect(() => {
    const status = statuses.find((status) => status.id === scene.id)
    if (status?.status === 'completed') {
      setIsReuploading(false)
    }
  }, [statuses])

  const handlePlayClick = () => {
    if (scene.character?.is_limited) {
      return;
    }
    setPlayClicked(true);
    setIsLoading(true);
    if (!scene.is_expired) {
      return;
    }
    setIsReuploading(true);
    ApiService.reuploadGeneratedScene(scene.id)
      .then((res) => {
        setUid(res.uid)
        if (res.status !== 'reuploading') {
          setIsReuploading(false);
          return;
        }
        dispatch(setReuploadingPolling(true));
      })
  };

  const handleVideoLoaded = () => {
    setIsLoading(false);
    setStreamLoaded(true);
    if (streamRef.current) {
      streamRef.current.play().then(() => {

      }).catch(() => {
        // play error
      });
    }
  }

  const dataLayerMovieProps = {
    user_id: user?.id,
    is_free_plan: user?.is_free_plan,
    scene_id: scene.id,
    is_tutorial: scene.is_tutorial,
    scene_type,
  }

  return (
    <Box sx={{
      position: 'relative',
      width: '100%',
      paddingTop: `${(1 / aspectRatio) * 100}%`,
      '& > img, & > div': commonStyles,
    }}>
      {!streamLoaded && (
        <>
          <img
            src={scene.thumbnail_url}
            alt="Description"
            style={{
              display: playClicked && !isLoading ? 'none' : 'block',
              backgroundColor: 'black',
            }}
            onClick={handlePlayClick}
          />
          <Duration duration={scene.duration} />
        </>
      )}
      {!streamLoaded && !isLoading && !scene.character?.is_limited && (
        <Box sx={iconContainerStyles} onClick={handlePlayClick}>
          <PlayCircleOutlineIcon sx={{ fontSize: 64, color: 'white' }} />
          <CloudIcon show={scene.is_expired} />
        </Box>
      )}
      {isLoading && (
        <Box sx={iconContainerStyles}>
          <CircularProgress />
          <CloudIcon show={scene.is_expired} isAnimation />
        </Box>
      )}
      {playClicked && !isReuploading && (
        <Box sx={commonStyles}>
          <Stream
            streamRef={streamRef}
            controls
            src={uid}
            width="100%"
            height="100%"
            autoplay
            onLoadedData={handleVideoLoaded}
            onPlaying={() => {
              setStartTime(streamRef.current?.currentTime)
            }}
            onPlay={() => {
              // シーク開始時にフィードバックダイアログが表示された場合、ドロップするとダイアログの下で再生が開始されてしまう
              // そのため、再生が開始されたら一旦一時停止する
              if (open) {
                streamRef.current?.pause();
                return;
              }
              setIsPlayed(true);
              if (!isPlayed) {
                // ビデオの再生が開始されたら、GTMイベントを送信
                TagManager.dataLayer({
                  dataLayer: {
                    event: "view_movie",
                    ...dataLayerMovieProps,
                  }
                });

                if (!scene.is_tutorial) {
                  dispatch(incrementSwitchedScenesCount(scene.id));
                }
              }
            }}
            onPause={() => {
              if (isFindMoreScenesTutorial) {
                setOpenTutorialDialog(true);
              }
              const endTime = streamRef.current?.currentTime
              if (endTime === undefined || startTime === undefined) {
                return;
              }
              const startInt = Math.round(startTime)
              const endInt = Math.round(endTime)
              // ビデオの視聴時間のGTMイベントを送信
              TagManager.dataLayer({
                dataLayer: {
                  event: "stop_movie",
                  start_time: startInt,
                  end_time: endInt,
                  viewing_time: endInt - startInt,
                  ...dataLayerMovieProps,
                }
              });
              setStartTime(undefined)
              // 一旦goodbadを隠す
              // お試しスイッチ動画の場合、フィードバックダイアログを表示
              /*
              if (!userDetail?.feedback_completed && userDetail?.plan_name === 'Free' && !isFeedbackCompleted && scene.is_switch_trial
                // 視聴時間が3秒未満の場合、シークバーの操作による停止で意図的な停止じゃない可能性があるため停止として扱わない
                && !(endTime - startTime < FEEDBACK_THRESHOLD_SECONDS)) {
                  dispatch(openSwitchedFeedbackDialog(scene.id));
                  setIsFeedbackCompleted(true)
              }
              */

              // ここからtimesale
              // onEndedを入れなくても終了時にonPausedも入るから問題なし
            }}
          />
        </Box>
      )}
      {scene.character?.is_limited && (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            position: 'absolute',
            top: 0,
            left: 0,
          }}
        >
          <Stack
            display="flex"
            padding="0 16px"
            justifyContent="center"
            alignItems="center"
            gap={2}
            sx={{
              height: '74px',
              width: 'calc(100% - 32px)',
              position: 'absolute',
              top: 'calc(50% - 37px)',
              left: 0,
            }}
          >
            <Typography
              variant="subtitle2"
              color="white"
            >
              <FormattedMessage id="video_stream.upgrade.required" />
            </Typography>
            <Button
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              onClick={() => {
                navigateTo(backToPlanPagePath)
                TagManager.dataLayer({
                  dataLayer: {
                    event: "gtm_event",
                    ga_event: "move_plan_from_video",
                    scene_id: scene.id,
                    user_id: user?.id,
                  }
                });
              }}
            >
              <FormattedMessage id="video_stream.upgrade.button" />
            </Button>
          </Stack>
        </Box>
      )}
      <FindMoreScenesDialog
        open={openTutorialDialog}
        closeDialog={() => setOpenTutorialDialog(false)}
      />
    </Box>
  );
};

export default SwitchedScenePlayer;
