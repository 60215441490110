import { useSelector } from "@/services/hooks";
import { Stack, Box, Button } from "@mui/material";
import {
  useDispatch
} from '@/services/hooks';
import TagManager from "react-gtm-module";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { closeTimesaleDialog } from "@/services/actions/commonActions";
import { backToPlanPagePath } from "@/constants";

type PropType = {
  disabledButton?: boolean;
};

const TimesaleBanner = (props: PropType) => {
  const { disabledButton } = props;
  const dispatch = useDispatch();

  const navigateTo = useNavigate();
  const { user } = useSelector((state) => state.user);
  const showTimesaleCountdown = useSelector((state) => state.common.timesale_countdown.show);

  if (!showTimesaleCountdown) {
    return null;
  }

  return (
    <Stack width="100%">
      <Stack width="100%">
        <img style={{ width: '100%' }} src="/home/timesale_ban@2x.png" srcSet="/home/timesale_ban@3x.png 2x,/home/timesale_ban@3x.png 3x" alt="banner" />
      </Stack>
      {!disabledButton && (
        <Box
          width="100%"
          padding="8px"
          display="flex"
          justifyContent="center"
          sx={{
            backgroundColor: '#FFF039',
          }}
        >
          <Button
            color="inherit"
            variant="contained"
            onClick={() => {
              navigateTo(backToPlanPagePath)
              TagManager.dataLayer({
                dataLayer: {
                  event: "gtm_event",
                  ga_event: "move",
                  user_id: user?.id,
                  from: 'timesale_button',
                  to: backToPlanPagePath,
                }
              });
              dispatch(closeTimesaleDialog());
            }}
            sx={{
              borderRadius: '8px',
              border: 'solid 2px #000000',
              fontWeight: 700,
              background: '#FFFFFF',
              color: '#000000',
              fontSize: '15px',
              ':hover': {
                opacity: 0.8,
              }
            }}
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              gap={1}
            >
              <FormattedMessage id="home.timesale.button" />
              <Box
                sx={{
                  background: '#0288D1',
                  lineHeight: 1,
                  borderRadius: '50%',
                  width: '13.35px',
                  height: '13.35px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <ArrowForwardIosIcon
                  sx={{
                    color: '#FFF77B',
                    fontSize: '10px',
                  }}
                />
              </Box>
            </Box>
          </Button>
        </Box>
      )}
    </Stack>
  )
}
export default TimesaleBanner;