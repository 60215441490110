import { Box, Button, Dialog, DialogActions, DialogContent, Stack, TextField, Typography } from '@mui/material';
import {
  useDispatch,
  useSelector
} from '@/services/hooks';
import { FormattedMessage } from 'react-intl';
import { closeGrandpayInfoInputDialog, openSnackbar } from '@/services/actions/commonActions';
import { useEffect, useState } from 'react';
import { createPurchaseTemporaryId, normalizePhoneNumber, UserUpdate, validateUserName } from '@/services/models';
import { updateUser } from '@/services/actions/userActions';
import { ApiService } from '@/services/api';
import TagManager from "react-gtm-module";

const GrandpayInfoInputDialog = () => {
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.user);
  const { grandpay_info_input_dialog } = useSelector(state => state.common);
  const [loading, setLoading] = useState<boolean>(false);
  const onClose = () => {
    dispatch(closeGrandpayInfoInputDialog());
    setLoading(false);
  }
  const [userInfo, setUserInfo] = useState<UserUpdate>({})
  useEffect(() => {
    if (!user) {
      return;
    }
    setUserInfo({
      name: user.name,
      phone: user.phone,
    })
  }, [user])


  const isInvalidName = !!userInfo?.name && !validateUserName(userInfo.name);

  const isInvalidId = (!grandpay_info_input_dialog.item_id && !grandpay_info_input_dialog.plan_id)
    || (!!grandpay_info_input_dialog.item_id && !!grandpay_info_input_dialog.plan_id)

  return (
    <div>
      <Dialog
        open={grandpay_info_input_dialog.open}
        onClose={onClose}
      >
        <DialogContent
          sx={{
            maxWidth: "345px",
            p: 2,
            pb: 0,
          }}
        >
          <Stack
            justifyContent="center"
            alignItems="center"
            gap={2}
          >
            <Typography fontSize="16px" fontWeight={400}>
              {grandpay_info_input_dialog.item_id ? (
                <FormattedMessage id="user.info.input.dialog_description.item" values={{ amount: 28 }} />
              ) : (
                <FormattedMessage id="user.info.input.dialog_description.plan" values={{ plan: 'Bronze' }} />
              )}
            </Typography>
            <TextField
              fullWidth
              required
              focused
              color="text"
              label={<FormattedMessage id="user.info.name" />}
              value={userInfo.name || ''}
              onChange={(e) => setUserInfo(v => {
                return { ...v, name: e.target.value }
              })}
              error={isInvalidName}
            />
            {isInvalidName && (
              <Typography color="error" fontSize="12px" mt={-2}>
                <FormattedMessage id="user.info.name.invalid" />
              </Typography>
            )}
            <TextField
              fullWidth
              required
              focused
              color="text"
              label={<FormattedMessage id="user.info.phone" />}
              value={userInfo.phone || ''}
              onChange={(e) => {
                const value = normalizePhoneNumber(e.target.value)
                setUserInfo(v => {
                  return { ...v, phone: value }
                })
              }}
            />
            <Box px="13.5px" width="100%">
              <Button
                variant='contained'
                fullWidth
                disabled={loading || !userInfo.name || !userInfo.phone || isInvalidName}
                onClick={() => {
                  const name = userInfo.name
                  const phone = userInfo.phone
                  if (!name || !phone) {
                    return;
                  }
                  setLoading(true);
                  dispatch(updateUser({ name, phone }))
                    .then(async () => {
                      if (isInvalidId) {
                        return;
                      }
                      let url = '';
                      if (grandpay_info_input_dialog.item_id) {
                        url = await ApiService.startGrandpayItem({
                          item_id: grandpay_info_input_dialog.item_id,
                          temporary_id: null,
                        })
                      }
                      if (grandpay_info_input_dialog.plan_id) {
                        const temporaryId = createPurchaseTemporaryId();
                        url = await ApiService.startGrandpayPlan({
                          plan_id: grandpay_info_input_dialog.plan_id,
                          temporary_id: temporaryId,
                        })
                        TagManager.dataLayer({
                          dataLayer: {
                            event: "gtm_event",
                            ga_event: "click_buy_plan_button",
                            user_id: user?.id,
                          }
                        });
                      }
                      window.location.href = url;
                    })
                    .catch(() => {
                      dispatch(openSnackbar(<FormattedMessage id="user.info.update.failed" />));
                    })
                }}
              >
                <FormattedMessage id="submit" />
              </Button>
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onClose}
          >
            <FormattedMessage id="close" />
          </Button>
        </DialogActions>
      </Dialog>
    </div >
  );
};

export default GrandpayInfoInputDialog;
