import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useNavigateWithQueryAndHash } from "@/services/hooks.ts";
import { getUserInfo, loginXUser } from "@/services/actions/userActions.ts";
import { openSnackbar } from "@/services/actions/commonActions.ts";
import { FormattedMessage } from "react-intl";
import TagManager from "react-gtm-module";

const XLogin: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const navigateTo = useNavigateWithQueryAndHash();
  const [isSucceeded, setIsSucceeded] = useState(false);

  useEffect(() => {
    // 2回ロードされてログイン失敗することがあるため、1回しか通らないように制御
    if (isSucceeded) {
      return;
    }
    const handleXCallback = async () => {
      TagManager.dataLayer({
        dataLayer: {
          event: "gtm_event",
          ga_event: "redirect_x_login",
        }
      });
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');

      if (!code) {
        console.error('No authorization code returned from X');
        navigateTo("/users/login");
        return;
      }

      try {
        const res = await dispatch(loginXUser(code));
        if (res.success) {
          await dispatch(getUserInfo());
          navigateTo("/?login=true");
          setIsSucceeded(true);
          return;
        } else {
          dispatch(openSnackbar(<FormattedMessage id="user.login.login_failed_x" />));
          throw new Error('X Login failed');
        }
      } catch (error) {
        console.error('Error during X callback handling:', error);
        navigateTo("/users/login");
      }

    };

    handleXCallback();
  }, [navigate]);

  return '';
};

export default XLogin;
