import _ from 'lodash';
import SceneComp from "@/components/Scene";
import SceneWithImageComp from "@/components/Scene/Image";
import { ApiService } from "@/services/api";
import { useDispatch, useSelector, useUrlParams } from "@/services/hooks";
import { useTutorialAlmostDone, Scene } from "@/services/models";
import { Box, BoxProps, Button, Divider, Stack, StackProps, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import TagManager from "react-gtm-module";
import { FormattedMessage } from "react-intl";
import BottomUpgradeBanner from '@/components/Banner/BottomUpgradeBanner';
import SuggestedScenes from '@/pages/Home/SuggestedScenes';
import { getUserDetail } from '@/services/actions/userActions';
import { openTimesaleDialog } from '@/services/actions/commonActions';

const LIMIT = 10;

type PropTypes = {
  isShowGenreSearch: boolean;
  isShow: boolean;
}

export default function Recommend(props: PropTypes) {
  const dispatch = useDispatch();
  const { getUrlParam } = useUrlParams();
  const sort = getUrlParam('sort') || 'recommend';
  const filter = getUrlParam('filter');

  const {
    isShowGenreSearch,
  } = props;
  const { user, detail } = useSelector((state) => state.user);
  const { is_two_column } = useSelector((state) => state.common);
  const isTutorialAlmostDone = useTutorialAlmostDone();
  const isTwoColumn = is_two_column && isTutorialAlmostDone;
  const isClickSelectSceneTutorial = user?.tutorial_step === 'click_select_scene_button';

  const twoColumnStyle: Partial<StackProps> = isTwoColumn ? {
    direction: 'row',
    flexWrap: 'wrap',
    gap: 2,
  } : {}

  const twoColumnBoxStyle: Partial<BoxProps> = isTwoColumn ? {
    width: 'calc(50% - 8px)',
  } : {}

  const [page, setPage] = useState(1);
  const [scenes, setScenes] = useState<Scene[]>([]);
  const [trialScenes, setTrialScenes] = useState<Scene[]>([]);
  const [isLastScene, setIsLastScene] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);

  const canShowSwitchTrial = trialScenes.length > 0 && user &&
    user.is_free_plan && !detail?.switch_trial_completed;

  useEffect(() => {
    if (!props.isShow) {
      return;
    }
    const isTargetUser = _.isNumber(user?.id) && user?.id as number % 10 <= 2;
    if (isTargetUser && !detail?.timesale_remain_sec && user?.is_free_plan) {
      // 一応リクエスト数減らすために条件かけておく
      ApiService.startTimesale().then(data => {
        if (data?.success) {
          dispatch(getUserDetail())
          dispatch(openTimesaleDialog())
        }
      }).catch((e) => {
        console.log(e);
      })
    }
  }, [props.isShow]);

  const init = () => {
    setPage(1);
    setIsLastScene(false);
    fetchScenes();
    fetchTrialScenes();
  };

  const fetchTrialScenes = () => {
    if (!user?.is_free_plan || detail?.switch_trial_completed) {
      setTrialScenes([]);
      return;
    }
    ApiService.getTrialScenes()
      .then((data) => {
        setTrialScenes(data);
      })
      .catch(() => setTrialScenes([]));
  }

  const fetchScenes = () => {
    const option = {
      filter,
      sort,
      page: 0
    };

    // 何らかの絞り込みがある時
    if (filter) {
      TagManager.dataLayer({
        dataLayer: {
          event: "gtm_event",
          ga_event: 'use_genre_search',
          user_id: user?.id,
          filter,
        }
      });
    }

    ApiService.getScenes(option)
      .then((data) => {
        if (data.length < LIMIT) {
          setIsLastScene(true);
        }
        setScenes(data);
      })
      .catch(() => setScenes([]));
  }

  const fetchNext = useCallback(() => {
    setLoadingMore(true);
    const option = {
      filter,
      sort,
      page,
    };

    ApiService.getScenes(option)
      .then((data) => {
        let isLastPage = false;
        if (data.length < LIMIT) {
          setIsLastScene(true);
          isLastPage = true;
        }
        TagManager.dataLayer({
          dataLayer: {
            event: "gtm_event",
            ga_event: "click_load_more",
            user_id: user?.id,
            page: page,
            is_last_page: isLastPage,
          }
        });
        setPage((before) => {
          return before + 1;
        });
        setScenes([...scenes, ...data]);
        setLoadingMore(false);
      });
  }, [scenes, page]);

  useEffect(() => {
    // fetchScenes();
    // fetchTrialScenes();
    // fetchSampleScenes();
    init();
  }, [isTutorialAlmostDone, user, sort, filter, detail?.is_continue_paid, detail?.paid_expired_at, detail?.switch_trial_completed, detail?.plan_name]);

  return (
    <>
      <SuggestedScenes />
      {!!canShowSwitchTrial && (
        <div style={{ backgroundColor: '#FAF7E1', padding: '16px' }}>

          <>
            <Stack mt={2}>
              <img style={{ width: '100%' }} src="/switch_trial_banner@3x.png"
                srcSet="/switch_trial_banner@3x.png 3x,/switch_trial_banner@2x.png 2x" alt="banner" />
            </Stack>
            <Box mt={2}>
              <Typography
                variant="body2"
                sx={{ color: 'rgba(0, 0, 0, 0.87)' }}
              >
                <FormattedMessage id="home.switch_trial_text" />
              </Typography>
            </Box>
            <Divider sx={{ mt: 2 }} />
          </>

          <Stack {...twoColumnStyle}>
            {trialScenes.map((scene, index) => (
              <Box
                key={`${scene.id}_${index}`}
                sx={{ position: 'relative' }}
                {...twoColumnBoxStyle}
              >
                {/*<SceneWithImageComp
                isTwoColumn={isTwoColumn}
                index={index}
                scene={scene}
                setScenes={setTrialScenes}
                showDivider={index < scenes.length - 1}
                bgColor="#FAF7E1"
                sceneType="myscene"
              />*/}
                <SceneComp
                  isTwoColumn={isTwoColumn}
                  isTutorial={index === 0 && isClickSelectSceneTutorial}
                  scene={scene}
                  showDivider={false}
                  isShowGenreSearch={false}
                  sceneType="myscene"
                  bgColor="#FAF7E1"
                  onFavoriteChanged={() => { }}
                  showCharacter={false} />
              </Box>
            ))}
          </Stack>
        </div >
      )}

      <Stack
        direction="column"
        spacing={0}
        marginTop={0}
        paddingLeft={2}
        paddingRight={2}
        gap={3}
        {...twoColumnStyle}
      >
        {scenes.map((scene, index) => !scene.is_switch_trial && (
          <Box key={`${scene.id}_${index}`} {...twoColumnBoxStyle}>
            {
              (scene.is_tutorial || user?.is_free_plan) ?
                <SceneComp
                  isTwoColumn={isTwoColumn}
                  scene={scene}
                  showDivider={index < scenes.length - 1}
                  isShowGenreSearch={isShowGenreSearch}
                  sceneType="myscene"
                  onFavoriteChanged={() => { }}
                  showCharacter={false} /> :
                <SceneWithImageComp
                  isTwoColumn={isTwoColumn}
                  index={index}
                  scene={scene}
                  setScenes={setScenes}
                  showDivider
                  isShowGenreSearch={isShowGenreSearch}
                  sceneType="myscene"
                />
            }
          </Box>
        ))}

        {scenes.length > 0 && !isLastScene && (
          <Stack
            marginTop={2}
            width="100%"
          >
            <Button
              onClick={fetchNext}
              variant="outlined"
              fullWidth
              disabled={loadingMore}
              sx={{ borderColor: 'rgba(240, 98, 146, 1)', background: 'rgba(250, 247, 225, 1)' }}
            >
              <FormattedMessage id="home.load_more" />
            </Button>
          </Stack>
        )}
        {
          isTutorialAlmostDone && user?.is_free_plan &&
          <BottomUpgradeBanner />
        }
      </Stack>
    </>
  );
}
