import React, { useState, useEffect, ReactElement } from "react";
import { useNavigate, Link } from "react-router-dom";

import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
  Button,
  FormHelperText,
} from "@mui/material";
import { color } from "@/services/themes";

import AppBar from "@/components/AppBar";
import { loginUser } from "@/services/actions/userActions";
import { openSnackbar } from "@/services/actions/commonActions";
import { useDispatch, useSelector } from '@/services/hooks';
import { FormattedMessage } from "react-intl";
import _ from "lodash";
import { trackingUrlParam } from "@/utils";
import TagManager from "react-gtm-module";
import BanceDspTag from "@/components/AdTag/bance_dsp_tag.tsx";

export default function UserLogin() {
  const [password, setPassword] = useState<string>("");
  // const [error, setError] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [serverError, setServerError] = useState<string | ReactElement>("");
  const [errors, setErrors] = useState<{ email: string | ReactElement; password: string | ReactElement }>({
    email: "",
    password: "",
  });

  const dispatch = useDispatch();

  const user = useSelector((state) => state.user.user);
  const loading = useSelector((state) => state.user.loading);
  const navigateTo = useNavigate();

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEmail(value);
    setErrors((prevErrors) => ({ ...prevErrors, email: validateEmail(value) }));
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPassword(value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      password: validatePassword(value),
    }));
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const validateEmail = (email: string): string | ReactElement => {
    const emailRegex = /^[a-zA-Z0-9+._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (!emailRegex.test(email)) {
      return <FormattedMessage id="common.invalid.email_format" />
    }
    return "";
  };

  const validatePassword = (password: string): string | ReactElement => {
    if (_.size(password) < 8) {
      return <FormattedMessage id="common.password.invalid_length" />
    }
    return "";
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const res = await dispatch(loginUser(email, password));
      if (res.success) {
        navigateTo("/?login=true");
      } else {
        setServerError(<FormattedMessage id="user.login.login_failed" />);
      }
    } catch (err) {
      setServerError(<FormattedMessage id="user.login.login_failed" />);
    }
  };

  // const loginWithGoogle = useGoogleLogin({
  //   onSuccess: async (tokenResponse) => {
  //     try {
  //       console.log('Token Response:', tokenResponse)
  //       const accessToken = tokenResponse.access_token;
  //
  //       // Google API を使ってユーザー情報を取得
  //       const userInfo = await axios.get('https://www.googleapis.com/oauth2/v1/userinfo?alt=json', {
  //         headers: {
  //           Authorization: `Bearer ${accessToken}`,
  //         },
  //       }).then(res => res.data);
  //
  //       const email = userInfo.email;
  //
  //       const res = await dispatch(loginGoogleUser(email, userInfo.id));
  //       if (res.success) {
  //         navigateTo("/?login=true");
  //       } else {
  //         setServerError(<FormattedMessage id="user.login.login_failed" />);
  //         if (res.status === 401) {
  //           dispatch(openSnackbar(<FormattedMessage id="user.login.login_failed_google" />));
  //         }
  //       }
  //       await dispatch(getUserInfo());
  //       navigateTo("/?login=true");
  //     } catch (err) {
  //       console.log('google login failed');
  //     }
  //   },
  //   onError: () => {
  //     console.log('Login Failed');
  //   },
  // });

  const loginWithX = () => {
    const clientId = import.meta.env.VITE_X_CLIENT_ID;
    const redirectUri = import.meta.env.VITE_X_LOGIN_REDIRECT_URI;
    const state = import.meta.env.VITE_X_STATE;
    const scope = 'tweet.read users.read';

    window.location.href = `https://twitter.com/i/oauth2/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&state=${state}&scope=${scope}&code_challenge=challenge&code_challenge_method=plain`;
  };

  const loginWithLine = () => {
    const clientId = import.meta.env.VITE_LINE_CLIENT_ID;
    const redirectUri = import.meta.env.VITE_LINE_LOGIN_REDIRECT_URI;
    const state = import.meta.env.VITE_LINE_STATE; // CSRF対策用のランダムな文字列
    const scope = 'openid%20email'; // 取得したい情報の範囲

    TagManager.dataLayer({
      dataLayer: {
        event: "gtm_event",
        ga_event: "click_line_login",
      }
    });

    window.location.href = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&state=${state}&scope=${scope}`;
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const recoverySuccess = urlParams.get("recovery") === "true";

    if (recoverySuccess) {
      dispatch(openSnackbar(<FormattedMessage id="user.login.password_change_done" />));
    }

    if (user) {
      navigateTo('/')
    }
  }, []);

  const isFormValid =
    !errors.email && !errors.password && email && password;

  return (
    <AppBar title={<FormattedMessage id="user.login.title" />}>
      { BanceDspTag() }
      <Stack spacing={2} marginY={3} marginX={2} paddingTop={2}>
        {loading ? (
          <Typography variant="body1">loading...</Typography>
        ) : user ? (
          <Typography variant="body1"><FormattedMessage id="user.login.already_login1" /> {user.email}
            <FormattedMessage id="user.login.already_login2" /></Typography>
        ) : (
          <Typography variant="body1"><FormattedMessage id="user.login.input_login_info" /></Typography>
        )}

        <Stack direction="row" justifyContent="space-around" alignItems="center">
          {/*Googleはエロ通らないので一旦消しておく*/}
          {/*<Stack direction="column" alignItems="center">*/}
          {/*  <Button*/}
          {/*    variant="outlined"*/}
          {/*    style={{*/}
          {/*      borderRadius: '50%',*/}
          {/*      padding: 0,*/}
          {/*      minWidth: 'auto',*/}
          {/*      width: '60px',*/}
          {/*      height: '60px',*/}
          {/*      display: 'flex',*/}
          {/*      justifyContent: 'center',*/}
          {/*      alignItems: 'center',*/}
          {/*      border: '1px solid rgba(0, 0, 0, 0.12)',*/}
          {/*    }}*/}
          {/*    onClick={() => loginWithGoogle()}*/}
          {/*  >*/}
          {/*    <img src="/social_google_logo@2x.png" alt="Google Logo" style={{ width: 24, height: 24 }} />*/}
          {/*  </Button>*/}
          {/*  <Typography variant="body2"><FormattedMessage id="user.login.google" /></Typography>*/}
          {/*</Stack>*/}
          <Stack direction="column" alignItems="center">
            <Button
              variant="outlined"
              style={{
                borderRadius: '50%',
                padding: 0,
                minWidth: 'auto',
                width: '60px',
                height: '60px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: '1px solid rgba(0, 0, 0, 0.12)',
              }}
              onClick={() => loginWithX()}
            >
              <img src="/social_x_logo@2x.png" alt="X Logo" style={{ width: 24, height: 24 }} />
            </Button>
            <Typography variant="body2"><FormattedMessage id="user.login.x" /></Typography>
          </Stack>
          <Stack direction="column" alignItems="center">
            <Button
              variant="outlined"
              style={{
                borderRadius: '50%',
                padding: 0,
                minWidth: 'auto',
                width: '60px',
                height: '60px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: '1px solid rgba(0, 0, 0, 0.12)',
              }}
              onClick={() => loginWithLine()}
            >
              <img src="/social_line_logo@2x.png" alt="Line Logo" style={{ width: 24, height: 24 }} />
            </Button>
            <Typography variant="body2"><FormattedMessage id="user.login.line" /></Typography>
          </Stack>
        </Stack>

        <FormControl variant="outlined" fullWidth>
          <TextField
            required
            label={<FormattedMessage id="user.login.login_id" />}
            variant="outlined"
            value={email}
            onChange={handleEmailChange}
            autoComplete="email"
            helperText={errors.email}
            error={!!errors.email}
          />
        </FormControl>

        <FormControl variant="outlined" fullWidth error={!!errors.password}>
          <InputLabel htmlFor="outlined-adornment-password">
            <FormattedMessage id="user.login.password" />
          </InputLabel>{" "}
          <OutlinedInput
            id="outlined-adornment-password"
            label={<FormattedMessage id="user.login.password_label" />}
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={handlePasswordChange}
            autoComplete="password"
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={togglePasswordVisibility}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
          {errors.password && <FormHelperText>{errors.password}</FormHelperText>}
        </FormControl>
        <Typography variant="body2">
          <Link to="/users/password-reset" style={{ color: "gray", textDecoration: "none" }}>
            <FormattedMessage id="user.login.forget_password1" /><span style={{ color: color.main }}><FormattedMessage
              id="user.login.forget_password2" /></span>
          </Link>
        </Typography>
        {serverError && <p style={{ color: "#d32f2f" }}>{serverError}</p>}

        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={!isFormValid}
        >
          <FormattedMessage id="user.login.login" />
        </Button>

        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            const queryWithHash = trackingUrlParam()
            navigateTo(`/users/signup${queryWithHash}`);
          }}
        >
          <FormattedMessage id="user.login.signup" />
        </Button>

        <div className="g-signin2" data-onsuccess="onSignIn"></div>
      </Stack>

    </AppBar>
  );
}
