import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ApiService } from "@/services/api.ts";
import { useDispatch, useNavigateWithQueryAndHash, useSelector } from "@/services/hooks.ts";
import { openSnackbar } from "@/services/actions/commonActions.ts";
import { FormattedMessage } from "react-intl";
import TagManager from "react-gtm-module";
import { getCookie, getLocale, getLoginPathWithDsp } from "@/utils.ts";

const XSignup: React.FC = () => {
  const { referrer } = useSelector(state => state.common);
  const dispatch = useDispatch();
  const navigateTo = useNavigateWithQueryAndHash();
  const [isSucceeded, setIsSucceeded] = useState(false);
  const bncDspKey = import.meta.env.VITE_DSP_BNC;
  const locale = getLocale();

  useEffect(() => {
    // 2回ロードされてユーザー作成成功しているのにログインされないことがあるため、1回しか通らないように制御

    if (isSucceeded) {
      return;
    }
    const handleXCallback = async () => {
      TagManager.dataLayer({
        dataLayer: {
          event: "gtm_event",
          ga_event: "redirect_x_signup",
        }
      });
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');
      // BANCE DSP広告用
      const bncClickId = getCookie(bncDspKey);
      const creativeType = getCookie('creative_type');
      const dspParam: {
        creative_type?: string;
        click_ids: { [key: string]: string }
      } = {
        click_ids: {},
      };
      if (bncClickId) {
        dspParam.click_ids.bnc = bncClickId;
      }
      if (creativeType) {
        dspParam.creative_type = creativeType;
      }

      if (!code) {
        console.error('No authorization code returned from X');
        navigateTo("/users/signup");
        dispatch(openSnackbar(<FormattedMessage id="user.signup.already_signup" />));
        return;
      }

      let referralCode = '';
      let invitationCode = '';
      let gentamaCode = '';
      let monetrackCode = '';

      try {
        const stateEncoded = urlParams.get('state') || '';
        const stateDecoded = JSON.parse(atob(stateEncoded));
        referralCode = stateDecoded.referralCode || "";
        invitationCode = stateDecoded.invitationCode || "";
        gentamaCode = stateDecoded.gentamaCode || "";
        monetrackCode = stateDecoded.monetrackCode || "";
      } catch (_e) {
        //
      }

      try {
        const data = await ApiService.registerWithX({
          code,
          referrer,
          dsp_param: Object.keys(dspParam).length > 0 ? dspParam : undefined,
          referral_code: referralCode,
          invitation_code: invitationCode,
          demo_type: creativeType,
          lang: locale
        });

        console.log("user registration: ", data);
        TagManager.dataLayer({
          dataLayer: {
            event: "gtm_event",
            ga_event: "signup",
            signup_type: "x",
          }
        });
        // tutorialの読み込みのためにnavigateToではなく window.location.href の変更にした
        setIsSucceeded(true);
        window.location.href = getLoginPathWithDsp({ gentamaCode, monetrackCode });
      } catch (err) {
        if (axios.isAxiosError(err) && err.response && err.response.status === 409) {
          navigateTo("/users/signup?social_login=false");
          return;
        } else {
          // TODO: signup成功しても通ってしまう
          // dispatch(openSnackbar(<FormattedMessage id="user.signup.social.error" />));
        }
        navigateTo("/users/signup");
      }
    };

    handleXCallback();
  }, []);

  return '';
};

export default XSignup;
