import _ from 'lodash';
import { closeSelectImageDialog, openSnackbar } from "@/services/actions/commonActions";
import { useDispatch, useNavigateWithQueryAndHash, useSelector } from "@/services/hooks";
import { Box, Button, Dialog, DialogContent, Stack, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { drawerWidth } from '@/constants';
import { useEffect, useMemo, useState } from 'react';
import { Scene, SceneImage } from '@/services/models';
import TagManager from 'react-gtm-module';
import { ApiService } from '@/services/api';
import { getLoginBonus, getUserDetail } from '@/services/actions/userActions';
import CharacterList from '../CharacterList';
// import RevivalPaymentBanner from '@/components/Banner/RevivalPaymentBanner';

const MAX_IMAGE_SWITCH = 10;

export default function SelectImageDialog() {
  const dispatch = useDispatch();
  const navigateTo = useNavigateWithQueryAndHash();
  const [loading, setLoading] = useState<boolean>(false);

  const { user, detail, daily_trial } = useSelector((state) => state.user);

  const [characterId, setCharacterId] = useState<number | null>(null);
  const { select_image_dialog } = useSelector((state) => state.common);
  const imageSwitchCount = daily_trial?.image_switch_count || 0;


  const closeDialog = () => {
    dispatch(closeSelectImageDialog());
  };

  useEffect(() => {
    if (!select_image_dialog.open) {
      return;
    }
    dispatch(getUserDetail())
  }, [select_image_dialog.open]);

  const sceneImage: SceneImage | null = useMemo(() => {
    const sceneImages = select_image_dialog.scene?.scene_images;
    if (!sceneImages) {
      return null;
    }
    for (const item of sceneImages) {
      if (item.character_id === characterId) {
        return item;
      }
    }
    return null;

  }, [characterId, select_image_dialog.scene, select_image_dialog.open])

  const disabled = !characterId || loading || !!sceneImage;

  const submitGTM = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "swap_image",
        user_id: user?.id,
        is_free_plan: user?.is_free_plan,
        scene_id: select_image_dialog.scene?.id,
        character_id: characterId,
      }
    });
  }

  const handleSubmit = async (e: React.MouseEvent) => {
    e.stopPropagation();
    setLoading(true);
    try {
      select_image_dialog.setIsLoading && select_image_dialog.setIsLoading(true);
      closeDialog();
      const data = await ApiService.switchImage(characterId, select_image_dialog.scene?.id as number);
      submitGTM();
      select_image_dialog.setScenes && select_image_dialog.setScenes((before) => {
        const scene = select_image_dialog.scene as Scene;
        const originalSceneId = data[0]?.original_scene_id;
        const next = [...before];
        const sceneIndex = _.findIndex(next, { id: originalSceneId });
        if (sceneIndex !== -1) {
          const newScene = { ...scene };
          newScene.scene_images = newScene.scene_images || [];
          newScene.scene_images = [...newScene.scene_images, ...data];
          next[sceneIndex] = newScene;
          return next;
        }
        return before;
      })

      const el = <FormattedMessage id="scene.image.dialog_message1" />
      dispatch(openSnackbar(el, 'bottom'));
      navigateTo(`/?tab=switched_image`);
    } catch (error: unknown) {
      const el = <FormattedMessage id="scene.image.dialog_error_message" />
      dispatch(openSnackbar(el, 'bottom'));
      console.log("generated error: ", error);
    } finally {
      dispatch(getLoginBonus());
      setLoading(false);
      select_image_dialog.setIsLoading && select_image_dialog.setIsLoading(false);
    }
  }

  return (
    <Dialog
      onClose={closeDialog}
      open={select_image_dialog.open}
      sx={{
        "& .MuiDialog-paper": {
          maxWidth: '343px',
          width: '100%',
          margin: '16px',
        },
        "& .MuiDialog-container": {
          sm: { marginLeft: `${drawerWidth}px` }
        },
      }}
    >
      <DialogContent
        sx={{
          flexDirection: 'column',
          alignItems: 'flex-start',
          alignSelf: 'stretch',
          padding: 0,
        }}
      >
        <Stack gap={2} p={2}>
          <Typography fontFamily="bold" textAlign="center">
            <FormattedMessage id="character_swap.title" />
          </Typography>

          <CharacterList
            isLogin={!!detail}
            characterId={characterId}
            createOnClick={(characterId: number) => {
              return () => {
                setCharacterId(characterId);
              }
            }}
            closeDialog={closeDialog}
          />

          <Box
            display="flex"
            justifyContent="space-between"
          >
            <Typography variant="body1">
              <FormattedMessage id="select_image.dialog.sub_title" />
            </Typography>

            <Typography>
              {` (${MAX_IMAGE_SWITCH - imageSwitchCount}/${MAX_IMAGE_SWITCH})`}
            </Typography>
          </Box>

          <Stack gap={1}>
            <Button
              fullWidth
              variant="contained"
              size="large"
              disabled={disabled}
              onClick={handleSubmit}
            >
              {sceneImage ?
                <FormattedMessage id="select_image.dialog.already" /> :
                <FormattedMessage id="select_image.dialog.button" />
              }
            </Button>
            <Typography variant="caption">
              <FormattedMessage id="switch.dialig.switch.caution" />
            </Typography>

            <Button
              variant="outlined"
              color="primary"
              size="medium"
              onClick={() => {
                closeDialog();
              }}
              sx={{
                marginTop: '12px',
                marginBottom: '8px',
              }}
            >
              <FormattedMessage id="cancel" />
            </Button>
          </Stack>
          {/* <RevivalPaymentBanner showText textSize="small" onClick={closeDialog} /> */}
        </Stack>
      </DialogContent>
    </Dialog>
  );
}