/**
 * 新規マイキャラクター追加ページ
 * 下記のページから遷移できる
 * - 「+」アバターアイコンから
 * - キャラクター管理ページから
 */
import React, { ReactElement, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Buffer } from "buffer";

import {
  Button,
  Stack,
  TextField,
  Typography,
  CircularProgress,
  Box,
} from "@mui/material";
import { Upload } from "@mui/icons-material";
import AppBar from "@/components/AppBar";
import { ApiService } from "@/services/api";
import { openCharacterSwapDialog, openPlanDialog } from "@/services/actions/commonActions";
import { useDispatch, useSelector, useUrlParams } from '@/services/hooks';
import axios from "axios";
import { FormattedMessage } from "react-intl";
import { ImageUploadModal } from "@/components/common/ImageUploadModal";
import { getUserDetail } from "@/services/actions/userActions";
import TagManager from "react-gtm-module";
import { Genre, useTutorialAlmostDone } from "@/services/models.ts";
import CharacterGenreList from "@/components/CharacterGenre/CharacterGenreList";
import CharacterGuideDialog from "@/components/common/CharacterGuideDialog";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import UploadCharacterButtonHint from "@/components/common/Tutorial/UploadCharacterButtonHint";
import InputCharacterNameHint from "@/components/common/Tutorial/InputCharacterNameHint";
import SelectGenreHint from "@/components/common/Tutorial/SelectGenreHint";
import SubmitCharacterHint from "@/components/common/Tutorial/SubmitCharacterHint";

export default function CharacterNew() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { getUrlParam } = useUrlParams();
  // スイッチダイアログから遷移してきた場合はscene_idを持っている
  const fromSceneId = getUrlParam('scene_id')
  const isTutorialTarget = user?.tutorial_step && user?.tutorial_step === 'switch_scene'
  const tutorialAlmostDone = useTutorialAlmostDone()
  const [tutorialSubStep, setTutorialSubStep] = useState<'upload_character_button' | 'input_character_name' | 'select_genre' | 'submit' | 'done'>('upload_character_button')
  const fileRef = useRef<HTMLInputElement | null>(null);
  const [image, setImage] = useState<string>();
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [name, setName] = useState<string>("");
  const [createdCharacterId, setCreatedCharacterId] = useState<number | null>();
  const [errorMessage, setErrorMessage] = useState<string | ReactElement | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [isImageModalOpen, setImageModalOpen] = useState(false);
  const [selectedGenres, setSelectedGenres] = useState<number[]>([]);
  const [genres, setGenres] = useState<Genre[]>([])

  const navigateTo = useNavigate();
  const MAX_SIZE = 5 * 1024 * 1024; // 5MB
  const IMAGE_MAX_HEIGHT = 640; // 5MB
  const ALLOWED_TYPES = ["image/jpeg", "image/png"];

  useEffect(() => {
    dispatch(getUserDetail()).then((data) => {
      if (data && data.character.plan_remain < 1) {
        dispatch(openPlanDialog('character'));
      }
    })
  }, []);

  useEffect(() => {
    ApiService.fetchGenres().then((data) => {
      setGenres(data);
    }).catch(
      (error) => console.error("Error:", error));
  }, []);

  const resizeImage = (imageElement: HTMLImageElement, file: File) => {
    const { naturalWidth, naturalHeight } = imageElement;
    const maxSize = IMAGE_MAX_HEIGHT; // maximum size in pixels
    if (naturalWidth > maxSize || naturalHeight > maxSize) {
      const aspectRatio = naturalWidth / naturalHeight;
      const newWidth = aspectRatio > 1 ? maxSize : Math.round(maxSize * aspectRatio);
      const newHeight = aspectRatio > 1 ? Math.round(maxSize / aspectRatio) : maxSize;
      const canvas = document.createElement("canvas");
      canvas.width = newWidth;
      canvas.height = newHeight;
      const ctx = canvas.getContext("2d");
      ctx?.drawImage(imageElement, 0, 0, newWidth, newHeight);
      const resizedUrl = canvas.toDataURL(file.type);
      imageElement.src = resizedUrl;
      setPreviewUrl(resizedUrl);
    } else {
      setPreviewUrl(imageElement.src);
    }
  };

  const validateImage = (image: File) => {
    if (!(image && image.name)) {
      return "invalid file object "
    }

    if (image.size > MAX_SIZE) {
      return "File is too large";
    }

    if (!ALLOWED_TYPES.includes(image.type)) {
      return "File type is not allowed";
    }

    return '';
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file && file.type.startsWith("image/")) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const result = reader.result as string;
        const imageElement = new Image();
        imageElement.src = result;
        imageElement.onload = () => {
          resizeImage(imageElement, file);
        };
        setImage(result);
        TagManager.dataLayer({
          dataLayer: {
            event: "gtm_event",
            ga_event: "upload_character_image",
            user_id: user?.id,
          }
        });
        setImageModalOpen(true);
        setTutorialSubStep('input_character_name')
      };
      reader.readAsDataURL(file);
    } else {
      // image ではないとのエラーメッセージ？
    }
  };

  const handleSubmit = async () => {
    if (!image || !previewUrl) return;

    if (!name) {
      alert("名前を入力してください。");
      return;
    }

    const fileData = previewUrl.replace(/^data:\w+\/\w+;base64,/, '')
    const decodedFile = Buffer.from(fileData, 'base64')
    const file = new File([decodedFile], `${name}.png`, { type: 'image/png' })

    const error = validateImage(file);
    if (error) {
      alert(error);
      return;
    }

    try {
      setLoading(true);
      const data = await ApiService.createCharacter(name, file, selectedGenres);

      if (data && data.id) {
        TagManager.dataLayer({
          dataLayer: {
            event: "gtm_event",
            ga_event: "generate_character",
            character_id: data.id,
            user_id: user?.id,
          }
        });
        setCreatedCharacterId(data.id);
        setTutorialSubStep('done')
      } else {
        console.log("failed to get character data");
        setErrorMessage(<FormattedMessage id="character.new.error_face_not_found" />);
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 403) {
          dispatch(openPlanDialog('character'));
        }
      }
      console.error("Error uploading image:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleCancel = () => {
    navigateTo("/");
  };

  const handleSearch = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "gtm_event",
        ga_event: "search_image",
        user_id: user?.id,
      }
    });
    window.open(
      "https://www.google.com/search?tbm=isch&q=%E5%A5%B3%E5%84%AA+%E6%AD%A3%E9%9D%A2",
      "_blank",
      "noopener,noreferrer"
    );
  };

  useEffect(() => {
    if (!createdCharacterId) {
      return;
    }
    navigateTo(`/`, { state: { createdCharacterId: createdCharacterId } });
    if (!fromSceneId) {
      return;
    }
    // 遷移元のシーンがある場合は、そのシーンのスイッチダイアログを表示する
    ApiService.getScene(Number(fromSceneId)).then((scene) => {
      dispatch(openCharacterSwapDialog(scene));
    })
  }, [fromSceneId, createdCharacterId]);

  const handleGenreClick = (genre: number) => {
    setSelectedGenres(prevSelected =>
      prevSelected.includes(genre)
        ? prevSelected.filter(item => item !== genre)
        : [...prevSelected, genre]
    );
    setTutorialSubStep('submit')
  };

  return (
    <AppBar title={<FormattedMessage id="character.new.title" />}>
      <Stack spacing={2} margin={2} paddingTop={2}>
        <Typography variant="button" display="block" gutterBottom style={{ marginBottom: 8 }}>
          <FormattedMessage id="character.new.message1" />
        </Typography>

        <Stack
          alignItems="center"
          gap={2}
          direction="column"
          position="relative"
        >
          {!!isTutorialTarget && tutorialSubStep === 'upload_character_button' &&
            <UploadCharacterButtonHint />
          }
          {!previewUrl && (
            <Button
              startIcon={<OpenInNewIcon />}
              onClick={handleSearch}
              variant="contained"
              fullWidth
              size="large"
              sx={{ zIndex: isTutorialTarget && tutorialSubStep === 'upload_character_button' ? 1201 : 0, }}
            >
              <FormattedMessage id="character.new.from_search" />
            </Button>
          )}
          <Stack
            direction="row"
            gap={2}
            alignItems="center"
            justifyContent="space-between"
            width={"100%"}
          >
            {previewUrl && (
              <Stack>
                <img
                  src={previewUrl}
                  alt="preview"
                  style={{ width: "100%", cursor: "pointer", display: 'inline-blcok' }}
                  onClick={() => {
                    fileRef.current?.click();
                    setImageModalOpen(true);
                  }}
                />
              </Stack>
            )}
            {!previewUrl && (
              <Stack width={"100%"}>
                <Button
                  component="label"
                  variant="outlined"
                  startIcon={<Upload />}
                  size="large"
                  sx={{
                    zIndex: isTutorialTarget && tutorialSubStep === 'upload_character_button' ? 1201 : 0,
                    padding: '6px 12px',
                    fontSize: '14px',
                    backgroundColor: 'white',
                    '&:hover': {
                      backgroundColor: 'white',
                    },
                  }}
                >
                  <FormattedMessage id="character.new.upload" />
                  <input
                    id="upload-button"
                    accept="image/*"
                    type="file"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                </Button>
              </Stack>
            )}
            <Stack position={"relative"} width={"100%"}>
              {!!isTutorialTarget && tutorialSubStep === 'input_character_name' &&
                <InputCharacterNameHint />
              }
              <Box
                sx={{ zIndex: isTutorialTarget && tutorialSubStep === 'input_character_name' ? 1201 : 0 }}
                component="form"
                onSubmit={(e) => {
                  e.preventDefault()
                  if (name && isTutorialTarget && tutorialSubStep === 'input_character_name') {
                    setTutorialSubStep('select_genre')
                  }
                }}
              >
                <TextField
                  required
                  fullWidth
                  id="outlined-required"
                  label={<FormattedMessage id="character.new.input_name" />}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  sx={{ backgroundColor: 'white' }}
                  onBlur={() => {
                    if (name && isTutorialTarget && tutorialSubStep === 'input_character_name') {
                      setTutorialSubStep('select_genre')
                    }
                  }}
                />
              </Box>
            </Stack>
          </Stack>
          <ImageUploadModal
            image={image}
            isImageModalOpen={isImageModalOpen}
            handleClose={() => setImageModalOpen(false)}
            setPreviewUrl={setPreviewUrl}
          />
        </Stack>

        <CharacterGuideDialog />

        <Box position={"relative"}>
          {!!isTutorialTarget && tutorialSubStep === 'select_genre' &&
            <SelectGenreHint />
          }
          <Stack gap={2} position="relative" sx={{
            zIndex: isTutorialTarget && tutorialSubStep === 'select_genre' ? 1201 : 0,
            backgroundColor: 'white',
          }}>
            <Stack direction="row" gap="5px" alignItems="baseline">
              <Typography fontSize={18}>
                <FormattedMessage id="character.new.add_tag" />
              </Typography>
              <Typography fontSize={12} color="error">
                <FormattedMessage id="character.new.add_tag_label" />
              </Typography>
            </Stack>
            <Box
              display="flex"
              flexWrap="wrap"
              gap="6px"
            >
              {
                <CharacterGenreList
                  genres={genres}
                  handleGenreClick={handleGenreClick}
                  selectedGenres={selectedGenres}
                />
              }
            </Box>
          </Stack>
        </Box>
        <Box textAlign="center" position="relative">
          <Button
            fullWidth
            variant="contained"
            disabled={!image || !name || selectedGenres.length === 0 || loading}
            sx={{
              marginTop: '16px',
              padding: '8px 22px',
              maxWidth: '284px',
            }}
            onClick={handleSubmit}
          >
            {loading ? (
              <>
                <CircularProgress size={24} color="inherit" />
                <span style={{ marginLeft: 4 }}><FormattedMessage id="character.new.generating" /></span>
              </>
            ) : (
              <span><FormattedMessage id="character.new.create_character" /></span>
            )}
          </Button>
          <Typography
            variant="body2"
            sx={{ marginTop: '8px !important', marginBottom: '8px !important' }}
          >
            <FormattedMessage id="character.new.create_character_comment" />
          </Typography>
          {isTutorialTarget && tutorialSubStep === 'submit' &&
            <SubmitCharacterHint />
          }
        </Box>
        <Box height={2}></Box>

        {!user?.is_free_plan && (
          <Stack direction="column" gap={2} marginTop={2}>
            <img src="/user_voice@2x.png" srcSet={"/user_voice@2x.png 2x, /user_voice@3x.png 3x"} alt="ユーザーボイス" style={{ width: "100%" }} />
          </Stack>
        )}
        <Stack direction="column" gap={2} marginTop={2}>
          <img src="/chara_new/hint@2x.jpg" srcSet={"/chara_new/hint@2x.jpg 2x, /chara_new/hint@3x.jpg 3x"} alt="キャラクター作成ヒント" style={{ width: "100%" }} />
          <img src="/chara_new/hint2@2x.jpg" srcSet={"/chara_new/hint2@2x.jpg 2x, /chara_new/hint2@3x.jpg 3x"} alt="キャラクター作成ヒント2" style={{ width: "100%" }} />
        </Stack>

        {tutorialAlmostDone && (
          <Button
            disabled={loading}
            variant="text"
            onClick={handleCancel}
          >
            <FormattedMessage id="character.new.cancel" />
          </Button>
        )}
      </Stack>
      {
        errorMessage && (
          <div style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)", backgroundColor: "white", padding: "20px", width: "343px", height: "fit-content", overflowY: "auto", boxShadow: "4px 4px 10px gray" }}>
            <Typography variant="h6" gutterBottom>
              <FormattedMessage id="character.new.error" />
            </Typography>
            <Typography variant="body1">
              {errorMessage}
            </Typography>
            <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
              <Button variant="contained" onClick={() => setErrorMessage(null)}>
                <FormattedMessage id="character.new.ok" />
              </Button>
            </div>
          </div>
        )
      }
    </AppBar >
  );
}
