//export const MEDIA_ACCEPTS = "image/*,video/mp4,image/gif";
export const MEDIA_ACCEPTS = "video/mp4";

export const drawerWidth = 240;
export const appBarHeight = 64;
export const appBarMiniHeight = 56;
export const timesalesCountdownHeight = 40;
export const isAdult = window.location.pathname.indexOf('/normal') !== 0;
export const cfCustomerCode = 'oley77jhqtu7sxpf'

// export const backToMypagePath = '/mypage';
export const backToMypagePath = '/users/plan';
export const backToPlanPagePath = '/users/plan';

export const countries = [
  { code: 'AF', labelId: 'user.info.country.af' },
  { code: 'AX', labelId: 'user.info.country.ax' },
  { code: 'AL', labelId: 'user.info.country.al' },
  { code: 'DZ', labelId: 'user.info.country.dz' },
  { code: 'AS', labelId: 'user.info.country.as' },
  { code: 'AD', labelId: 'user.info.country.ad' },
  { code: 'AO', labelId: 'user.info.country.ao' },
  { code: 'AI', labelId: 'user.info.country.ai' },
  { code: 'AQ', labelId: 'user.info.country.aq' },
  { code: 'AG', labelId: 'user.info.country.ag' },
  { code: 'AR', labelId: 'user.info.country.ar' },
  { code: 'AM', labelId: 'user.info.country.am' },
  { code: 'AW', labelId: 'user.info.country.aw' },
  { code: 'AU', labelId: 'user.info.country.au' },
  { code: 'AT', labelId: 'user.info.country.at' },
  { code: 'AZ', labelId: 'user.info.country.az' },
  { code: 'BS', labelId: 'user.info.country.bs' },
  { code: 'BH', labelId: 'user.info.country.bh' },
  { code: 'BD', labelId: 'user.info.country.bd' },
  { code: 'BB', labelId: 'user.info.country.bb' },
  { code: 'BY', labelId: 'user.info.country.by' },
  { code: 'BE', labelId: 'user.info.country.be' },
  { code: 'BZ', labelId: 'user.info.country.bz' },
  { code: 'BJ', labelId: 'user.info.country.bj' },
  { code: 'BM', labelId: 'user.info.country.bm' },
  { code: 'BT', labelId: 'user.info.country.bt' },
  { code: 'BO', labelId: 'user.info.country.bo' },
  { code: 'BQ', labelId: 'user.info.country.bq' },
  { code: 'BA', labelId: 'user.info.country.ba' },
  { code: 'BW', labelId: 'user.info.country.bw' },
  { code: 'BV', labelId: 'user.info.country.bv' },
  { code: 'BR', labelId: 'user.info.country.br' },
  { code: 'IO', labelId: 'user.info.country.io' },
  { code: 'BN', labelId: 'user.info.country.bn' },
  { code: 'BG', labelId: 'user.info.country.bg' },
  { code: 'BF', labelId: 'user.info.country.bf' },
  { code: 'BI', labelId: 'user.info.country.bi' },
  { code: 'KH', labelId: 'user.info.country.kh' },
  { code: 'CM', labelId: 'user.info.country.cm' },
  { code: 'CA', labelId: 'user.info.country.ca' },
  { code: 'CV', labelId: 'user.info.country.cv' },
  { code: 'KY', labelId: 'user.info.country.ky' },
  { code: 'CF', labelId: 'user.info.country.cf' },
  { code: 'TD', labelId: 'user.info.country.td' },
  { code: 'CL', labelId: 'user.info.country.cl' },
  { code: 'CN', labelId: 'user.info.country.cn' },
  { code: 'CX', labelId: 'user.info.country.cx' },
  { code: 'CC', labelId: 'user.info.country.cc' },
  { code: 'CO', labelId: 'user.info.country.co' },
  { code: 'KM', labelId: 'user.info.country.km' },
  { code: 'CG', labelId: 'user.info.country.cg' },
  { code: 'CD', labelId: 'user.info.country.cd' },
  { code: 'CK', labelId: 'user.info.country.ck' },
  { code: 'CR', labelId: 'user.info.country.cr' },
  { code: 'CI', labelId: 'user.info.country.ci' },
  { code: 'HR', labelId: 'user.info.country.hr' },
  { code: 'CU', labelId: 'user.info.country.cu' },
  { code: 'CW', labelId: 'user.info.country.cw' },
  { code: 'CY', labelId: 'user.info.country.cy' },
  { code: 'CZ', labelId: 'user.info.country.cz' },
  { code: 'DK', labelId: 'user.info.country.dk' },
  { code: 'DJ', labelId: 'user.info.country.dj' },
  { code: 'DM', labelId: 'user.info.country.dm' },
  { code: 'DO', labelId: 'user.info.country.do' },
  { code: 'EC', labelId: 'user.info.country.ec' },
  { code: 'EG', labelId: 'user.info.country.eg' },
  { code: 'SV', labelId: 'user.info.country.sv' },
  { code: 'GQ', labelId: 'user.info.country.gq' },
  { code: 'ER', labelId: 'user.info.country.er' },
  { code: 'EE', labelId: 'user.info.country.ee' },
  { code: 'ET', labelId: 'user.info.country.et' },
  { code: 'FK', labelId: 'user.info.country.fk' },
  { code: 'FO', labelId: 'user.info.country.fo' },
  { code: 'FJ', labelId: 'user.info.country.fj' },
  { code: 'FI', labelId: 'user.info.country.fi' },
  { code: 'FR', labelId: 'user.info.country.fr' },
  { code: 'GF', labelId: 'user.info.country.gf' },
  { code: 'PF', labelId: 'user.info.country.pf' },
  { code: 'TF', labelId: 'user.info.country.tf' },
  { code: 'GA', labelId: 'user.info.country.ga' },
  { code: 'GM', labelId: 'user.info.country.gm' },
  { code: 'GE', labelId: 'user.info.country.ge' },
  { code: 'DE', labelId: 'user.info.country.de' },
  { code: 'GH', labelId: 'user.info.country.gh' },
  { code: 'GI', labelId: 'user.info.country.gi' },
  { code: 'GR', labelId: 'user.info.country.gr' },
  { code: 'GL', labelId: 'user.info.country.gl' },
  { code: 'GD', labelId: 'user.info.country.gd' },
  { code: 'GP', labelId: 'user.info.country.gp' },
  { code: 'GU', labelId: 'user.info.country.gu' },
  { code: 'GT', labelId: 'user.info.country.gt' },
  { code: 'GG', labelId: 'user.info.country.gg' },
  { code: 'GN', labelId: 'user.info.country.gn' },
  { code: 'GW', labelId: 'user.info.country.gw' },
  { code: 'GY', labelId: 'user.info.country.gy' },
  { code: 'HT', labelId: 'user.info.country.ht' },
  { code: 'HM', labelId: 'user.info.country.hm' },
  { code: 'VA', labelId: 'user.info.country.va' },
  { code: 'HN', labelId: 'user.info.country.hn' },
  { code: 'HK', labelId: 'user.info.country.hk' },
  { code: 'HU', labelId: 'user.info.country.hu' },
  { code: 'IS', labelId: 'user.info.country.is' },
  { code: 'IN', labelId: 'user.info.country.in' },
  { code: 'ID', labelId: 'user.info.country.id' },
  { code: 'IR', labelId: 'user.info.country.ir' },
  { code: 'IQ', labelId: 'user.info.country.iq' },
  { code: 'IE', labelId: 'user.info.country.ie' },
  { code: 'IM', labelId: 'user.info.country.im' },
  { code: 'IL', labelId: 'user.info.country.il' },
  { code: 'IT', labelId: 'user.info.country.it' },
  { code: 'JM', labelId: 'user.info.country.jm' },
  { code: 'JP', labelId: 'user.info.country.jp' },
  { code: 'JE', labelId: 'user.info.country.je' },
  { code: 'JO', labelId: 'user.info.country.jo' },
  { code: 'KZ', labelId: 'user.info.country.kz' },
  { code: 'KE', labelId: 'user.info.country.ke' },
  { code: 'KI', labelId: 'user.info.country.ki' },
  { code: 'KP', labelId: 'user.info.country.kp' },
  { code: 'KR', labelId: 'user.info.country.kr' },
  { code: 'KW', labelId: 'user.info.country.kw' },
  { code: 'KG', labelId: 'user.info.country.kg' },
  { code: 'LA', labelId: 'user.info.country.la' },
  { code: 'LV', labelId: 'user.info.country.lv' },
  { code: 'LB', labelId: 'user.info.country.lb' },
  { code: 'LS', labelId: 'user.info.country.ls' },
  { code: 'LR', labelId: 'user.info.country.lr' },
  { code: 'LY', labelId: 'user.info.country.ly' },
  { code: 'LI', labelId: 'user.info.country.li' },
  { code: 'LT', labelId: 'user.info.country.lt' },
  { code: 'LU', labelId: 'user.info.country.lu' },
  { code: 'MO', labelId: 'user.info.country.mo' },
  { code: 'MK', labelId: 'user.info.country.mk' },
  { code: 'MG', labelId: 'user.info.country.mg' },
  { code: 'MW', labelId: 'user.info.country.mw' },
  { code: 'MY', labelId: 'user.info.country.my' },
  { code: 'MV', labelId: 'user.info.country.mv' },
  { code: 'ML', labelId: 'user.info.country.ml' },
  { code: 'MT', labelId: 'user.info.country.mt' },
  { code: 'MH', labelId: 'user.info.country.mh' },
  { code: 'MQ', labelId: 'user.info.country.mq' },
  { code: 'MR', labelId: 'user.info.country.mr' },
  { code: 'MU', labelId: 'user.info.country.mu' },
  { code: 'YT', labelId: 'user.info.country.yt' },
  { code: 'MX', labelId: 'user.info.country.mx' },
  { code: 'FM', labelId: 'user.info.country.fm' },
  { code: 'MD', labelId: 'user.info.country.md' },
  { code: 'MC', labelId: 'user.info.country.mc' },
  { code: 'MN', labelId: 'user.info.country.mn' },
  { code: 'ME', labelId: 'user.info.country.me' },
  { code: 'MS', labelId: 'user.info.country.ms' },
  { code: 'MA', labelId: 'user.info.country.ma' },
  { code: 'MZ', labelId: 'user.info.country.mz' },
  { code: 'MM', labelId: 'user.info.country.mm' },
  { code: 'NA', labelId: 'user.info.country.na' },
  { code: 'NR', labelId: 'user.info.country.nr' },
  { code: 'NP', labelId: 'user.info.country.np' },
  { code: 'NL', labelId: 'user.info.country.nl' },
  { code: 'NC', labelId: 'user.info.country.nc' },
  { code: 'NZ', labelId: 'user.info.country.nz' },
  { code: 'NI', labelId: 'user.info.country.ni' },
  { code: 'NE', labelId: 'user.info.country.ne' },
  { code: 'NG', labelId: 'user.info.country.ng' },
  { code: 'NU', labelId: 'user.info.country.nu' },
  { code: 'NF', labelId: 'user.info.country.nf' },
  { code: 'MP', labelId: 'user.info.country.mp' },
  { code: 'NO', labelId: 'user.info.country.no' },
  { code: 'OM', labelId: 'user.info.country.om' },
  { code: 'PK', labelId: 'user.info.country.pk' },
  { code: 'PW', labelId: 'user.info.country.pw' },
  { code: 'PS', labelId: 'user.info.country.ps' },
  { code: 'PA', labelId: 'user.info.country.pa' },
  { code: 'PG', labelId: 'user.info.country.pg' },
  { code: 'PY', labelId: 'user.info.country.py' },
  { code: 'PE', labelId: 'user.info.country.pe' },
  { code: 'PH', labelId: 'user.info.country.ph' },
  { code: 'PN', labelId: 'user.info.country.pn' },
  { code: 'PL', labelId: 'user.info.country.pl' },
  { code: 'PT', labelId: 'user.info.country.pt' },
  { code: 'PR', labelId: 'user.info.country.pr' },
  { code: 'QA', labelId: 'user.info.country.qa' },
  { code: 'RE', labelId: 'user.info.country.re' },
  { code: 'RO', labelId: 'user.info.country.ro' },
  { code: 'RU', labelId: 'user.info.country.ru' },
  { code: 'RW', labelId: 'user.info.country.rw' },
  { code: 'BL', labelId: 'user.info.country.bl' },
  { code: 'SH', labelId: 'user.info.country.sh' },
  { code: 'KN', labelId: 'user.info.country.kn' },
  { code: 'LC', labelId: 'user.info.country.lc' },
  { code: 'MF', labelId: 'user.info.country.mf' },
  { code: 'PM', labelId: 'user.info.country.pm' },
  { code: 'VC', labelId: 'user.info.country.vc' },
  { code: 'WS', labelId: 'user.info.country.ws' },
  { code: 'SM', labelId: 'user.info.country.sm' },
  { code: 'ST', labelId: 'user.info.country.st' },
  { code: 'SA', labelId: 'user.info.country.sa' },
  { code: 'SN', labelId: 'user.info.country.sn' },
  { code: 'RS', labelId: 'user.info.country.rs' },
  { code: 'SC', labelId: 'user.info.country.sc' },
  { code: 'SL', labelId: 'user.info.country.sl' },
  { code: 'SG', labelId: 'user.info.country.sg' },
  { code: 'SX', labelId: 'user.info.country.sx' },
  { code: 'SK', labelId: 'user.info.country.sk' },
  { code: 'SI', labelId: 'user.info.country.si' },
  { code: 'SB', labelId: 'user.info.country.sb' },
  { code: 'SO', labelId: 'user.info.country.so' },
  { code: 'ZA', labelId: 'user.info.country.za' },
  { code: 'GS', labelId: 'user.info.country.gs' },
  { code: 'SS', labelId: 'user.info.country.ss' },
  { code: 'ES', labelId: 'user.info.country.es' },
  { code: 'LK', labelId: 'user.info.country.lk' },
  { code: 'SD', labelId: 'user.info.country.sd' },
  { code: 'SR', labelId: 'user.info.country.sr' },
  { code: 'SJ', labelId: 'user.info.country.sj' },
  { code: 'SZ', labelId: 'user.info.country.sz' },
  { code: 'SE', labelId: 'user.info.country.se' },
  { code: 'CH', labelId: 'user.info.country.ch' },
  { code: 'SY', labelId: 'user.info.country.sy' },
  { code: 'TW', labelId: 'user.info.country.tw' },
  { code: 'TJ', labelId: 'user.info.country.tj' },
  { code: 'TZ', labelId: 'user.info.country.tz' },
  { code: 'TH', labelId: 'user.info.country.th' },
  { code: 'TL', labelId: 'user.info.country.tl' },
  { code: 'TG', labelId: 'user.info.country.tg' },
  { code: 'TK', labelId: 'user.info.country.tk' },
  { code: 'TO', labelId: 'user.info.country.to' },
  { code: 'TT', labelId: 'user.info.country.tt' },
  { code: 'TN', labelId: 'user.info.country.tn' },
  { code: 'TR', labelId: 'user.info.country.tr' },
  { code: 'TM', labelId: 'user.info.country.tm' },
  { code: 'TC', labelId: 'user.info.country.tc' },
  { code: 'TV', labelId: 'user.info.country.tv' },
  { code: 'UG', labelId: 'user.info.country.ug' },
  { code: 'UA', labelId: 'user.info.country.ua' },
  { code: 'AE', labelId: 'user.info.country.ae' },
  { code: 'GB', labelId: 'user.info.country.gb' },
  { code: 'US', labelId: 'user.info.country.us' },
  { code: 'UM', labelId: 'user.info.country.um' },
  { code: 'UY', labelId: 'user.info.country.uy' },
  { code: 'UZ', labelId: 'user.info.country.uz' },
  { code: 'VU', labelId: 'user.info.country.vu' },
  { code: 'VE', labelId: 'user.info.country.ve' },
  { code: 'VN', labelId: 'user.info.country.vn' },
  { code: 'VG', labelId: 'user.info.country.vg' },
  { code: 'VI', labelId: 'user.info.country.vi' },
  { code: 'WF', labelId: 'user.info.country.wf' },
  { code: 'EH', labelId: 'user.info.country.eh' },
  { code: 'YE', labelId: 'user.info.country.ye' },
  { code: 'ZM', labelId: 'user.info.country.zm' },
  { code: 'ZW', labelId: 'user.info.country.zw' }
];
