import { Stack, Typography, Fade, Box, IconButton } from "@mui/material";
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import { FormattedMessage } from "react-intl";
import { useSelector } from "@/services/hooks";
import { useState } from "react";
import { Close } from "@mui/icons-material";

export default function SubmitCharacterHint() {
  const user = useSelector(state => state.user.user);
  const [open, setOpen] = useState(true)
  const isTarget = user?.tutorial_step && user?.tutorial_step === 'switch_scene'
  if (!isTarget || !open) {
    return null;
  }
  return (
    <>
      <Fade in unmountOnExit timeout={300}>
        <Stack
          position="absolute"
          padding="14px 24px"
          gap={1}
          width="256px"
          sx={{
            backgroundColor: '#FFF',
            bottom: 'calc(100% + 16px)',
            right: 0,
            left: "50%",
            transform: "translateX(-50%)",
            borderRadius: '16px',
            zIndex: 1201,
            boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, .25)',
            '&::before': {
              content: '""',
              position: 'absolute',
              bottom: '-8px',
              right: 'calc(50% - 8px)',
              transform: 'translateX(-50%)',
              width: '0',
              height: '0',
              borderLeft: '8px solid transparent',
              borderRight: '8px solid transparent',
              borderTop: '8px solid #FFF',
            },
            '&::after': {
              content: '""',
              position: 'absolute',
              bottom: '-12px',
              right: 'calc(50% - 8px)',
              transform: 'translateX(-50%)',
              width: '0',
              height: '0',
              borderLeft: '8px solid transparent',
              borderRight: '8px solid transparent',
              borderTop: '8px solid rgba(0, 0, 0, .25)',
              zIndex: -1,
              filter: "blur(2px)",
            },
          }}
        >
          <Box
            position='absolute'
            right={1}
            top={1}
          >
            <IconButton onClick={() => {
              setOpen(false);
            }}>
              <Close fontSize="small" />
            </IconButton>
          </Box>
          <Typography
            variant="body1"
            color="rgba(0, 0, 0, 0.60)"
            sx={{
              fontSize: '10px',
              lineHeight: 1.5,
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
            }}
          >
            <TipsAndUpdatesIcon fontSize="small" sx={{ color: "#FFD54F" }} />
            <FormattedMessage id="hint.switch.how_to_use" />
          </Typography>

          <Typography
            variant="body1"
            sx={{
              fontWeight: 700,
            }}
          >
            設定ができたらボタンを押しましょう！
            {/* <FormattedMessage id="hint.switch.lets" /> */}
          </Typography>
        </Stack>
      </Fade>
    </>
  )
}