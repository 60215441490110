import { isAdult } from "@/constants";
import { useNavigateWithQueryAndHash, useSelector } from "@/services/hooks"
import { useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";

type Props = {
  enable?: boolean;
}

const TutorialDoneRequired = ({
  enable,
}: Props) => {
  const navigate = useNavigateWithQueryAndHash();
  const path = useLocation().pathname;
  const user = useSelector(state => state.user.user);
  const characters = useSelector(state => state.character.characters);
  const noCharacter = characters.length === 0;
  const tutorialStep = user?.tutorial_step
  useLayoutEffect(() => {
    if (!isAdult) {
      return;
    }
    if (!enable) {
      return;
    }
    if (tutorialStep === 'click_select_scene_button') {
      navigate('/')
      return;
    }
    if (tutorialStep === 'create_character') {
      navigate('/')
      return;
    }
    if (tutorialStep === 'switch_scene' && noCharacter) {
      navigate('/characters/new')
      return;
    }
    if (tutorialStep === 'switch_scene' && !noCharacter) {
      navigate('/')
      return;
    }
    return;
  }, [tutorialStep, noCharacter, enable, path]);

  // リダイレクトするだけのコンポーネントなので何も描画しない
  return null;
}

export default TutorialDoneRequired;