/**
 * キャラクター管理ページ
 * マイキャラクターの一覧表示、新規作成、編集、削除ができるページ
 */
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import _ from 'lodash';

import AppBar from "@/components/AppBar";
import {
  Avatar,
  Button,
  Card,
  CardHeader,
  Divider,
  IconButton,
  Stack,
} from "@mui/material";
import { Add, Edit } from '@mui/icons-material';

import { ApiService } from "@/services/api";
import { mainTheme } from "@/services/themes";
import RoundedTag from "@/components/RoundedTag/RoundedTag";
import { getRelativeTime } from "@/utils";
import { Character } from "@/services/models";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "@/services/hooks";
import { openPlanDialog } from "@/services/actions/commonActions";
import { getUserDetail } from "@/services/actions/userActions";
import TagManager from "react-gtm-module";

export default function CharacterIndex() {
  const [characters, setCharacters] = useState<Character[]>([])
  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  const { user, detail } = useSelector((state) => state.user);

  const handleClickCreate = () => {
    if (detail && detail.character.plan_remain < 1) {
      dispatch(openPlanDialog('character'));
    } else {
      navigateTo('/characters/new');
    }
  }

  useEffect(() => {
    dispatch(getUserDetail())

    ApiService.getMyCharacters()
      .then((data) => setCharacters(data))
      .catch((error) => console.error("Error:", error));
  }, []);

  return (
    <AppBar title={<FormattedMessage id="character.index.title" />}>

      <Stack spacing={2} marginX={2} marginY={3} gap={1} paddingTop={2}>
        <RoundedTag text={<FormattedMessage id="character.index.my_character" />} />

        {_.map(characters, (character, index) => (
          <div key={index}>
            <Divider></Divider>
            <Card
              sx={{
                boxShadow: "none",
                position: 'relative',
                marginTop: 1
              }}
            >
              <CardHeader
                sx={{ padding: "8px 0" }}
                avatar={
                  <Link to={`/characters/${character.id}`}>
                    <Avatar
                      src={character.url}
                      sx={{
                        filter: character.is_limited ? 'brightness(0.5)' : 'none',
                      }}
                    />
                  </Link>
                }
                title={character.name}
                // FIXME
                subheader={getRelativeTime(character.created_at)}
                onClick={() =>
                  TagManager.dataLayer({
                    dataLayer: {
                      event: "gtm_event",
                      ga_event: "click_character",
                      user_id: user?.id,
                      character_id: character.id,
                      from: "characters_page",
                    }
                  })
                }
              />

              <IconButton sx={{
                position: 'absolute',
                top: 8,
                right: 0,
              }}
                onClick={() => {
                  navigateTo(`/characters/${character.id}/edit`)
                }}
              >
                <Edit />
              </IconButton>
            </Card>
            {(index === characters.length - 1) && (
              <Divider sx={{ marginTop: 1 }}></Divider>
            )}
          </div>
        ))}

        <Stack alignItems="center">
          <Button
            variant="contained"
            startIcon={<Add />}
            fullWidth
            sx={{
              backgroundColor: mainTheme.palette.primary.main,
              padding: '6px 22px',
              color: 'white',
              maxWidth: '284px'
            }}
            onClick={handleClickCreate}
          >
            <span style={{ marginTop: 2 }}>{<FormattedMessage id="character.index.new_character_create" />}</span>
          </Button>
        </Stack>
      </Stack>
    </AppBar>
  );
}
