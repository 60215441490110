import { Helmet } from "react-helmet-async";

export const BanceDspTag = () => {
  return (
    <Helmet>
      <script async src="https://js.dsp.bance.jp/bncdmp.js?advid=167"></script>
      <script>
        {`(function(){
            window.bncdmp = window.bncdmp || {cmd: []};
            bncdmp.cmd.push(function() {
              bncdmp.run({
                'advid': 167
              });
            });
          })();`
        }
      </script>
    </Helmet>
  )
}

export default BanceDspTag;
