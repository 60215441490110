import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import {
  Box,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { color } from "@/services/themes";

import AppBar from "@/components/AppBar";
import CharacterList from '@/components/CharacterList';
import { openSnackbar } from "@/services/actions/commonActions";
import { useDispatch, useSelector, useUrlParams } from '@/services/hooks';
import { isAdult } from "@/constants";
import { FormattedMessage } from "react-intl";
import { ImportantNotice } from "@/components/common/ImportantNotice";

import GenreSearch from "@/pages/Home/GenreSearch";
import Banner from "@/pages/Home/Banner";
import Recommend from "@/pages/Home/Recommend";
import Favorite from "@/pages/Home/Favorite";
import Switched from "@/pages/Home/Switched";
import TagManager from "react-gtm-module";
import { getUnplayedSwitchedSceneCount } from "@/services/actions/sceneActions";
import ConditionalDisplay from "@/components/common/ConditionalDisplay";
import { trackingUrlParam } from "@/utils";
import { getCharacters } from "@/services/actions/characterActions";
import { getLoginBonus, getUserDetail } from "@/services/actions/userActions";
import SwitchTab from "@/components/Home/Switched/SwitchTab";
import { useTutorialAlmostDone } from "@/services/models";
// import OnetimeItemBanner from "@/components/Banner/OnetimeItemBanner";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const TAB_RECOMMENDATION = 0
const TAB_SWITCHED = 1
const TAB_FAVORITE = 2

export default function HomeIndex() {
  const { getUrlParam, unsetUrlParam } = useUrlParams();
  const completeToggle = useSelector((state) => state.common.progress.complete_toggle);
  const showSuggestionTip = useSelector((state) => state.scene.has_suggested);

  const location = useLocation();
  const dispatch = useDispatch();
  const navigateTo = useNavigate();
  const { user, loading } = useSelector((state) => state.user);
  const isTutorialAlmostDone = useTutorialAlmostDone();
  const unplayedCount = useSelector((state) => state.scene.unplayed_count);
  const [tabScrollPosition, setTabScrollPosition] = useState<{ [key: number]: number }>({
    [TAB_RECOMMENDATION]: 0,
    [TAB_SWITCHED]: 0,
    [TAB_FAVORITE]: 0,
  });
  const [switchedTabType, setSwitchedTabType] = useState<'scene' | 'image'>('scene');

  // const canShowSwitchTrial = user && user.is_free_plan && !detail?.switch_trial_completed;

  const tabNumberMapping: { [key: number]: string } = {
    [TAB_RECOMMENDATION]: 'recommend',
    [TAB_SWITCHED]: 'switch',
    [TAB_FAVORITE]: 'favorite',
  }

  const [value, setValue] = useState(TAB_RECOMMENDATION);

  const isShowGenreSearch = value === TAB_RECOMMENDATION
    && (!user?.is_free_plan || !!isTutorialAlmostDone);

  useEffect(() => {
    dispatch(getLoginBonus());
    // switch trialの判定に使ってる
    dispatch(getUserDetail());
  }, [])

  const tabValue = getUrlParam('tab');
  useEffect(() => {
    if (tabValue === 'switched') {
      setValue(TAB_SWITCHED);
      setSwitchedTabType('scene');
      unsetUrlParam('tab');
    } else if (tabValue === 'switched_image') {
      setValue(TAB_SWITCHED);
      setSwitchedTabType('image');
      unsetUrlParam('tab');
    } else if (tabValue === 'favorite') {
      setValue(TAB_FAVORITE);
      unsetUrlParam('tab');
    } else if (tabValue === 'recommend') {
      setValue(TAB_RECOMMENDATION);
      unsetUrlParam('tab');
    }
  }, [tabValue]);

  const getUnplayedCount = () => {
    dispatch(getUnplayedSwitchedSceneCount());
  }

  useEffect(() => {
    getUnplayedCount();
  }, [completeToggle])

  // タブが切り替わったら、前回そのタブでスクロールした位置に戻す
  // タブ切り替えののハンドラで実行するとスクロールが効かないので、useEffectで実行
  useEffect(() => {
    window.scrollTo(0, tabScrollPosition[value]);
  }, [value]);

  const handleChange = (_event: React.SyntheticEvent | null, newValue: number) => {
    setValue(newValue);
    setTabScrollPosition((prev) => ({ ...prev, [value]: window.scrollY }));
    TagManager.dataLayer({
      dataLayer: {
        event: "gtm_event",
        ga_event: "change_home_tab",
        tab_type: tabNumberMapping[newValue] || 'unknown',
        user_id: user?.id,
      }
    });
  };

  useEffect(() => {
    if (!loading) {
      if (!user && isAdult) {
        const queryWithHash = trackingUrlParam()
        navigateTo(`/demo${queryWithHash}`);
      }
    }
  }, [loading]);

  useEffect(() => {
    // キャラクター一覧を取得
    dispatch(getCharacters())

    // キャラクター作成後のメッセージ表示
    if (location.state?.createdCharacterId) {
      dispatch(openSnackbar(<FormattedMessage id="character.created" />));
    }

  }, [user]);

  return (
    <AppBar title={<FormattedMessage id="home.title" />} isPrimary={true}>
      <Stack direction="column" margin={0} marginBottom={2} gap={3} >
        <ImportantNotice />

        {/* バナー */}
        {isTutorialAlmostDone && (
          <Banner />
        )}

        {isTutorialAlmostDone && (
          <Box width="100%" paddingX={2}
            sx={{
              "&:first-child": {
                pt: 2,
              }
            }}>
            <CharacterList
              isLogin={!!user}
              createOnClick={(characterId: number) => {
                return () => {
                  TagManager.dataLayer({
                    dataLayer: {
                      event: "gtm_event",
                      ga_event: "click_character",
                      user_id: user?.id,
                      character_id: characterId,
                      from: "home",
                    }
                  });
                  navigateTo(`/characters/${characterId}`)
                }
              }}
              closeDialog={() => { }}
            />
          </Box>
        )}

        <Box
          sx={{
            width: '100%',
            background: "rgba(255, 255, 255, 0.95)",
            zIndex: 100,
            mt: -3,
            pt: 1.25,
          }}
          position="sticky"
          top={"56px"}
        >
          <Box position="relative" sx={{ borderBottom: 1, borderColor: 'divider' }} paddingLeft={2} paddingRight={2}>
            <Tabs value={value} onChange={handleChange} aria-label="tabs" variant="fullWidth"
              sx={{ overflow: 'visible', '& .MuiTabs-scroller': { overflow: 'visible' } }}
            >
              <Tab
                label={<FormattedMessage id="home.recommend" />}
                {...a11yProps(TAB_RECOMMENDATION)}
                sx={{ paddingBottom: '4px', fontSize: '15px', whiteSpace: 'nowrap' }}
                color="text.secondary"
              />
              <Tab
                label={<FormattedMessage id="home.switched" />}
                {...a11yProps(TAB_SWITCHED)}
                sx={{
                  paddingBottom: '4px',
                  background: 'white',
                  fontSize: '15px',
                  whiteSpace: 'nowrap',
                }}
                color="text.secondary"
                disabled={!isTutorialAlmostDone}
              />
              <Tab
                label={<FormattedMessage id="home.favorite" />}
                {...a11yProps(TAB_FAVORITE)}
                sx={{ paddingBottom: '4px', fontSize: '15px', whiteSpace: 'nowrap' }}
                color="text.secondary"
                disabled={!isTutorialAlmostDone}
              />

              {
                unplayedCount > 0 && (
                  <Typography
                    fontFamily="roboto"
                    fontWeight={500}
                    fontSize="11px"
                    sx={{
                      color: '#FFFFFF',
                      background: color.main,
                      display: 'flex',
                      position: 'absolute',
                      height: '16px',
                      width: '16px',
                      borderRadius: '50%',
                      alignItems: 'center',
                      justifyContent: 'center',
                      left: '63%',
                      top: '12px',
                    }}
                  >
                    {unplayedCount}
                  </Typography>
                )
              }
            </Tabs>
            {
              showSuggestionTip && (<Box
                p="8px 0"
                sx={{
                  display: 'flex',
                  position: 'absolute',
                  borderRadius: '5px',
                  alignItems: 'center',
                  justifyContent: 'center',
                  left: 'calc(16px + 15% - 50px)',
                  top: '-40px',
                  backgroundColor: "rgba(2, 136, 209, 1)",
                  width: '100px',
                  '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: '100%',
                    left: '50%',
                    marginLeft: '-5px',
                    width: '0',
                    height: '0',
                    borderStyle: 'solid',
                    borderWidth: '5px 5px 0',
                    borderColor: `rgba(2, 136, 209, 1) transparent transparent`,
                  },
                }}
              >
                <Typography
                  textAlign="center"
                  fontSize="10px"
                  fontWeight={500}
                  color='white'
                  sx={{ color: '#FFFFFF' }}
                >
                  次にスイッチする
                  <br />
                  動画を探そう
                </Typography>
              </Box>
              )
            }

            {
              !user?.is_free_plan && value === TAB_SWITCHED && (
                <SwitchTab
                  tabType={switchedTabType}
                  setTabType={setSwitchedTabType}
                />
              )
            }
          </Box>
          {/* ジャンル絞り込み */}
          <Box mt={2} {...(isShowGenreSearch ? {} : { display: 'none' })}>
            <GenreSearch />
          </Box>
        </Box>
        {/* TODO: 決済が停止されているので表示しない */}
        {/* {
          isFinishedTutorial && (
            <OnetimeItemBanner />
          )
        } */}
        {/* 各々のタブの出しわけ */}
        <Box width="100%" overflow="hidden">
          <Box display="flex" flexDirection="row" width="300%" sx={{
            marginLeft: `-${value * 100}%`,
            marginRight: `${value * 100}%`,
            transition: 'margin-left 0.3s, margin-right 0.3s',
          }}>
            <Box width="calc(100% / 3)" flex={1} minHeight="110vh">
              <ConditionalDisplay isShow={value === TAB_RECOMMENDATION} hideDelay={300}>
                <Recommend
                  isShow={value === TAB_RECOMMENDATION}
                  isShowGenreSearch={isShowGenreSearch}
                />
              </ConditionalDisplay>
            </Box>
            <Box width="calc(100% / 3)" flex={1}>
              <ConditionalDisplay isShow={value === TAB_SWITCHED} hideDelay={300}>
                <Switched
                  tabType={switchedTabType}
                />
              </ConditionalDisplay>
            </Box>
            <Box width="calc(100% / 3)" flex={1}>
              <ConditionalDisplay isShow={value === TAB_FAVORITE} hideDelay={300}>
                <Favorite
                  isShow={value === TAB_FAVORITE}
                />
              </ConditionalDisplay>
            </Box>
          </Box>
        </Box>
      </Stack>
    </AppBar >
  );
}
