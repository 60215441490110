import { setReuploadingPolling, setReuploadingStatus } from "@/services/actions/commonActions";
import { ApiService } from "@/services/api";
import { useDispatch, useSelector } from "@/services/hooks";
import { useEffect, useRef } from "react";

export default function ReuploadingStatusPoller() {
  const dispatch = useDispatch();
  const { polling } = useSelector(state => state.common.reuploading);
  const intervalId = useRef<number>()

  const checkReuploadingStatus = async () => {
    const data = await ApiService.check_reuploading_status();
    dispatch(setReuploadingStatus(data.swaps));

    const hasReuploading = !!data.swaps.find((swap) => {
      return swap.status === 'reuploading'
    })
    if (hasReuploading) {
      dispatch(setReuploadingPolling(true));
      return
    }
    dispatch(setReuploadingPolling(false));
    clearInterval(intervalId.current);
    intervalId.current = undefined
  }

  useEffect(() => {
    checkReuploadingStatus();
  }, [])
  useEffect(() => {
    if (polling && !intervalId.current) {
      // 生成中は、3秒ごとに何フィード生成できたかを確認する
      intervalId.current = setInterval(checkReuploadingStatus, 3000);
    }
    return () => clearInterval(intervalId.current);
  }, [polling]);

  // ポーリングだけできればいいので描画はしない
  return null;
}
