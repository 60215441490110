import { useMemo } from 'react';
import { Box, Button, ButtonBase, Stack, Typography } from "@mui/material";

import CheckIcon from '@mui/icons-material/Check';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import GenreDialog from "@/components/Dialog/genre";
import { useEffect, useState } from "react";
import _ from "lodash";
import { Genre } from "@/services/models";
import { ApiService } from "@/services/api";
import { useSelector, useUrlParams } from "@/services/hooks";
import GenreButton from "@/components/common/GenreButton";
import { FormattedMessage } from 'react-intl';
import { resolveGenreParam } from '@/utils';
import TagManager from "react-gtm-module";

const fixedGenreList = [
  '新作', '準新作', '週間ランキング', '累計ランキング'
];

export default function GenreSearch() {
  const { getUrlParam, setUrlParam } = useUrlParams();

  const [genres, setGenres] = useState<Genre[]>([])
  const [isShowSort, setIsShowSort] = useState(false);
  const [isShowGenreModal, setIsShowGenreModal] = useState(false);
  const { user } = useSelector(state => state.user);

  const sort = _.includes(['recommend', 'latest', 'popularity'], getUrlParam('sort')) ? getUrlParam('sort') : 'recommend';
  const filter = getUrlParam('filter') || '';

  useEffect(() => {
    ApiService.fetchGenres().then((data) => {
      setGenres(data);
    }).catch(
      (error) => console.error("Error:", error));
  }, []);

  const titleText = useMemo(() => {
    if (filter) {
      return `${filter}の動画一覧`;
    }
    return '';
  }, [filter]);

  // 不正filterを排除
  useEffect(() => {
    if (_.isEmpty(genres)) {
      return;
    }
    const list = filter.split(',');
    const newList = [];
    for (const name of fixedGenreList) {
      if (list.indexOf(name) !== -1) {
        newList.push(name);
      }
    }
    _.each(list, (name) => {
      const genre = _.find(genres, { name });
      if (genre) {
        newList.push(name);
      }
    })
    setUrlParam('filter', newList.join(','));
  }, [filter, genres]);


  const genreButtons = useMemo(() => {
    const list = _.filter(filter.split(','));
    return _.map(list, (val) => {
      if (fixedGenreList.includes(val)) {
        for (const name of fixedGenreList) {
          if (val === name) {
            return (
              <GenreButton
                key={name}
                label={name}
                onClick={() => {
                  setUrlParam('filter', resolveGenreParam(getUrlParam('filter'), name));
                }}
              />
            )
          }
        }
      } else {
        const genre = _.find(genres, { name: val });
        if (!genre) { return null }
        return (
          <GenreButton
            key={genre?.id}
            label={genre?.name}
            onClick={() => {
              setUrlParam('filter', resolveGenreParam(getUrlParam('filter'), genre?.name));
            }}
          />
        )
      }
    });
  }, [filter, genres]);

  return (
    <Stack>
      {titleText && (
        <Stack
          paddingX={2}
          marginBottom={2}
        >
          <Typography variant="subtitle1">
            {titleText}
          </Typography>
        </Stack>
      )}
      {
        !!titleText && (
          <Box
            padding="0 8px"
            display="flex"
            overflow="scroll"
            gap={1}
            marginBottom={2}
          >
            {genreButtons}
          </Box>
        )
      }
      <Stack
        padding={2}
        sx={{
          backgroundColor: '#333333',
        }}
      >
        <Box
          paddingX={2}
          justifyContent="space-between"
          alignItems="center"
          display="flex"
        >
          <Box
            position="relative"
          >
            <ButtonBase
              onClick={() => { setIsShowSort(before => !before) }}
            >
              <Box
                display="flex"
                justifyContent="center"
                alignItems="cetter"
                gap={1}
              >
                <Typography color="#FFFFFF">
                  <FormattedMessage id={`search.${sort}`} />
                </Typography>
                <KeyboardArrowDownIcon
                  color="primary"
                  sx={{ fontSize: '24px' }}
                />
              </Box>
            </ButtonBase>
            {isShowSort && (
              <Stack
                width="186px"
                sx={{
                  position: "absolute",
                  top: '34px',
                  left: '-10px',
                  zIndex: 999,
                  boxShadow: `0px 4px 18px 3px #0000001F, 
              0px 10px 14px 1px #00000024, 
              0px 6px 6px -3px #00000033;`,
                  backgroundColor: 'white',
                }}
              >
                <ButtonBase
                  onClick={() => {
                    setUrlParam('sort', 'recommend');
                    setIsShowSort(false);
                    TagManager.dataLayer({
                      dataLayer: {
                        event: 'gtm_event',
                        ga_event: 'use_sort',
                        user_id: user?.id,
                        sort_type: 'recommend',
                      }
                    });
                  }}
                >
                  <Box
                    width="100%"
                    padding="8px 16px"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography
                      variant="body1"
                      textAlign="left"
                      color={sort === 'recommend' ? 'primary' : undefined}
                    >
                      <FormattedMessage id="search.recommend" />
                    </Typography>
                    {sort === 'recommend' && <CheckIcon color="primary" />}
                  </Box>
                </ButtonBase>
                <ButtonBase
                  onClick={() => {
                    setUrlParam('sort', 'latest');
                    setIsShowSort(false);
                    TagManager.dataLayer({
                      dataLayer: {
                        event: 'gtm_event',
                        ga_event: 'use_sort',
                        user_id: user?.id,
                        sort_type: 'latest',
                      }
                    });
                  }}
                >
                  <Box
                    width="100%"
                    padding="8px 16px"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography
                      variant="body1"
                      textAlign="left"
                      color={sort === 'latest' ? 'primary' : undefined}
                    >
                      <FormattedMessage id="search.latest" />
                    </Typography>
                    {sort === 'latest' && <CheckIcon color="primary" />}
                  </Box>
                </ButtonBase>

                <ButtonBase
                  onClick={() => {
                    setUrlParam('sort', 'popularity');
                    setIsShowSort(false);
                    TagManager.dataLayer({
                      dataLayer: {
                        event: 'gtm_event',
                        ga_event: 'use_sort',
                        user_id: user?.id,
                        sort_type: 'popularity',
                      }
                    });
                  }}
                >
                  <Box
                    width="100%"
                    padding="8px 16px"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography
                      variant="body1"
                      textAlign="left"
                      color={sort === 'popularity' ? 'primary' : undefined}
                    >
                      <FormattedMessage id="search.popularity" />
                    </Typography>
                    {sort === 'popularity' && <CheckIcon color="primary" />}
                  </Box>
                </ButtonBase>

              </Stack>
            )}
          </Box>
          <Button
            variant="contained"
            color="primary"
            startIcon={<FilterAltIcon />}
            onClick={() => {
              setIsShowGenreModal(true)
            }}
          >
            絞り込み
          </Button>
        </Box>

        <GenreDialog
          open={isShowGenreModal}
          onClose={() => {
            setIsShowGenreModal(false)
          }}
          genre_list={genres}
        />
      </Stack>
    </Stack>
  )
}
