import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl, InputLabel,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import {
  useDispatch,
  useSelector
} from '@/services/hooks';
import { FormattedMessage, useIntl } from 'react-intl';
import { closeWebpayInfoInputDialog, openSnackbar } from '@/services/actions/commonActions';
import { createPurchaseTemporaryId, normalizePhoneNumber, UserUpdate, validateUserName } from '@/services/models';
import { updateUser } from '@/services/actions/userActions';
import { ApiService } from '@/services/api';
import { useEffect, useState } from "react";
import { getLocale } from "@/utils.ts";
import { countries } from "@/constants.ts";

const WebpayInfoInputDialog = () => {
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.user);
  const { webpay_info_input_dialog } = useSelector(state => state.common);
  const [loading, setLoading] = useState<boolean>(false);
  const locale = getLocale();
  const onClose = () => {
    dispatch(closeWebpayInfoInputDialog());
    setLoading(false);
  }
  const [userInfo, setUserInfo] = useState<UserUpdate>({});
  useEffect(() => {
    if (!user) {
      return;
    }
    setUserInfo({
      name: user.name,
      phone: user.phone,
      address: user.address,
      city: user.city,
      state: user.state,
      country: user.country || 'JP',
      postal_code: user.postal_code,
    })
  }, [user])


  const isInvalidName = !!userInfo?.name && !validateUserName(userInfo.name);
  const intl = useIntl();

  return (
    <Dialog
      open={webpay_info_input_dialog.open}
      onClose={onClose}
    >
      <DialogContent
        sx={{
          maxWidth: "345px",
          p: 2,
          pb: 0,
        }}
      >
        <Stack
          justifyContent="center"
          alignItems="center"
          gap={2}
        >
          <Typography fontSize="16px" fontWeight={400}>
            <FormattedMessage id="user.info.input.webpay_dialog_description.item" />
          </Typography>
          <TextField
            fullWidth
            required
            focused
            color="text"
            label={<FormattedMessage id="user.info.name" />}
            value={userInfo.name || ''}
            placeholder="Input name"
            onChange={(e) => setUserInfo(v => {
              return { ...v, name: e.target.value }
            })}
            error={isInvalidName}
          />
          {isInvalidName && (
            <Typography color="error" fontSize="12px" mt={-2}>
              <FormattedMessage id="user.info.name.invalid" />
            </Typography>
          )}
          <TextField
            fullWidth
            required
            focused
            color="text"
            label={<FormattedMessage id="user.info.phone" />}
            value={userInfo.phone || ''}
            placeholder="Input phone number"
            onChange={(e) => {
              const value = normalizePhoneNumber(e.target.value)
              setUserInfo(v => {
                return { ...v, phone: value }
              })
            }}
          />
          <TextField
            fullWidth
            required
            focused
            color="text"
            label={<FormattedMessage id="user.info.postal_code" />}
            value={userInfo.postal_code || ''}
            placeholder='Input postal code'
            onChange={(e) => {
              const value = e.target.value
              setUserInfo(v => {
                return { ...v, postal_code: value }
              })
            }}
          />
          <TextField
            fullWidth
            required
            focused
            color="text"
            label={<FormattedMessage id="user.info.state" />}
            value={userInfo.state || ''}
            placeholder="Input state"
            onChange={(e) => {
              const value = e.target.value
              setUserInfo(v => {
                return { ...v, state: value }
              })
            }}
          />
          <TextField
            fullWidth
            required
            focused
            color="text"
            label={<FormattedMessage id="user.info.city" />}
            value={userInfo.city || ''}
            placeholder="Input city"
            onChange={(e) => {
              const value = e.target.value
              setUserInfo(v => {
                return { ...v, city: value }
              })
            }}
          />
          <TextField
            fullWidth
            required
            focused
            color="text"
            label={<FormattedMessage id="user.info.address" />}
            value={userInfo.address || ''}
            placeholder="Input address"
            onChange={(e) => {
              const value = e.target.value
              setUserInfo(v => {
                return { ...v, address: value }
              })
            }}
          />
          <FormControl fullWidth required>
            <InputLabel
              shrink
              sx={{
                backgroundColor: 'white',
                padding: '0 4px',
              }}
            >
              <FormattedMessage id="user.info.country" />
            </InputLabel>
            <Autocomplete
              options={countries}
              getOptionLabel={(option) => intl.formatMessage({ id: option.labelId })}
              value={countries.find((c) => c.code === userInfo.country) || null}
              onChange={(_, newValue) => {
                setUserInfo((v) => ({
                  ...v,
                  country: newValue?.code || '',
                }));
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select country"
                />
              )}
              isOptionEqualToValue={(option, value) => option.code === value.code}
            />
          </FormControl>
          <Box px="13.5px" width="100%">
            <Button
              variant='contained'
              fullWidth
              disabled={loading || !userInfo.name || !userInfo.phone || !userInfo.postal_code || !userInfo.state ||
                !userInfo.city || !userInfo.address || !userInfo.country || isInvalidName}
              onClick={ async() => {
                const name = userInfo.name
                const phone = userInfo.phone
                const address = userInfo.address
                const city = userInfo.city
                const state = userInfo.state
                const country = userInfo.country
                const postal_code = userInfo.postal_code
                if (!name || !phone || !address || !city || !state || !country || !postal_code) {
                  return;
                }
                setLoading(true);
                try {
                  await dispatch(updateUser({ name, phone, address, city, state, country, postal_code }));

                  // どちらか一方が存在する場合以外は処理を中断
                  if (Boolean(webpay_info_input_dialog.item_id) === Boolean(webpay_info_input_dialog.plan_id)) {
                    return;
                  }

                  const temporaryId = webpay_info_input_dialog.plan_id ? createPurchaseTemporaryId() : null;

                  const webpayResponse = await ApiService.submitFormToWebpay(
                    {
                      item_id: webpay_info_input_dialog.item_id,
                      plan_id: webpay_info_input_dialog.plan_id,
                      is_jp: locale === 'ja',
                      temporary_id: temporaryId,
                    }
                  );
                  const tempDiv = document.createElement('div');
                    tempDiv.innerHTML = webpayResponse;
                    document.body.appendChild(tempDiv);
                    const form = tempDiv.querySelector('form');
                    if (form) {
                      form.submit();
                    }
                } catch (e) {
                  console.error('Failed to redirect to Webpay:', e);
                  dispatch(
                    openSnackbar(<FormattedMessage id="user.info.update.failed" />)
                  );
                } finally {
                  setLoading(false);
                }
              }}
            >
              <FormattedMessage id="submit" />
            </Button>
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
        >
          <FormattedMessage id="close" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WebpayInfoInputDialog;
