import { useEffect, useRef, useState } from "react";
import { Button, Typography, Dialog, Stack, DialogContent, IconButton, Box, Grow } from "@mui/material";
import { Circle, Close } from "@mui/icons-material";
import { FormattedMessage } from "react-intl";
import TagManager from "react-gtm-module";
import { useDispatch, useSelector } from "@/services/hooks";
import { getCharacters } from "@/services/actions/characterActions";

const ComparePicture = ({ index }: { index: number }) => {
  return (
    <Stack gap={2} width="100%">
      <Stack direction="row" width="100%" gap={2}>
        <img
          src={`/chara_new/slide0${index}.webp`}
          srcSet={`/chara_new/slide0${index}@2x.webp 2x, /chara_new/slide0${index}@3x.webp 3x`}
          alt="slide"
          width="100%"
        />
      </Stack>
      <Typography variant="subtitle1">
        <FormattedMessage id={`character.guide.slide${index}`} />
      </Typography>
    </Stack>
  )
}

const slides = [
  <ComparePicture index={1} />,
  <ComparePicture index={2} />,
  <ComparePicture index={3} />,
  <ComparePicture index={4} />,
  <ComparePicture index={5} />,
  <Stack px={2} gap={2}>
    <Typography variant="subtitle1">
      <FormattedMessage id={`character.guide.slide6`} />
    </Typography>
    <img src={`/chara_new/slide06.webp`} srcSet={`/chara_new/slide06@2x.webp 2x, /chara_new/slide06@3x.webp 3x`} alt="samples" width="100%" />
  </Stack>
]

const CharacterGuideDialog = () => {
  const characters = useSelector((state) => state.character.characters);
  const dispatch = useDispatch();
  const charactersCount = characters.length;
  const [openSampleDialog, setOpenSampleDialog] = useState(false);
  const [transformOrigin, setTransformOrigin] = useState("50% 50%"); // 初期位置
  const ref = useRef<HTMLButtonElement>(null);
  const [activeStep, setActiveStep] = useState(0);
  const user = useSelector((state) => state.user.user);
  const maxSteps = slides.length;
  const dataLayer = {
    event: "gtm_event",
    ga_event: 'turn_chara_guide_slide_page',
    user_id: user?.id,
    current_page: activeStep,
    characters_count: charactersCount,
  };

  const handleOpen = (target: HTMLButtonElement) => {
    const rect = target.getBoundingClientRect(); // ボタンの座標を取得
    setTransformOrigin(`${rect.left + rect.width / 2}px ${rect.top + rect.height / 2}px`);
    setOpenSampleDialog(true);
    setActiveStep(0);
  };
  const handleClose = () => {
    setOpenSampleDialog(false);
    TagManager.dataLayer({
      dataLayer: {
        ...dataLayer,
        turn_type: 'close',
      }
    });
  };

  useEffect(() => {
    if (charactersCount === 0 && ref.current) {
      // ストアにデータが入っていないだけの可能性があるので、キャラクターを取得する
      dispatch(getCharacters()).then((data) => {
        // 取得したデータも空だったらダイアログを開く
        if (data !== null && data.length === 0 && ref.current) {
          handleOpen(ref.current);
        }
      });
    }
  }, [charactersCount]);

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1)
    TagManager.dataLayer({
      dataLayer: {
        ...dataLayer,
        turn_type: 'next',
      }
    });
  }
  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1)
    TagManager.dataLayer({
      dataLayer: {
        ...dataLayer,
        turn_type: 'back',
      }
    });
  }


  return (
    <Box sx={{ display: "flex", justifyContent: "right", mt: 4 }}>
      <Button
        variant="text"
        color="primary"
        onClick={(e) => {
          handleOpen(e.currentTarget)
          TagManager.dataLayer({
            dataLayer: {
              ...dataLayer,
              turn_type: 'open',
            }
          });
        }}
        ref={ref}
        sx={{
          fontSize: "14px",
          height: "14px",
          padding: 0,
        }}
      >
        良い動画を作るための顔写真のコツ
      </Button>
      <Dialog
        open={openSampleDialog}
        TransitionComponent={Grow}
        TransitionProps={{
          timeout: 400,
          style: { transformOrigin }, // クリック位置から拡大
          easing: "ease-in-out",
        }}
        PaperProps={{
          sx: {
            transformOrigin, // 初期位置
            p: 2.25,
            m: 2,
            position: 'relative',
            overflow: 'visible',
            width: 'calc(100% - 36px)',
          }
        }}
      >
        <IconButton
          sx={{ position: "absolute", top: "-32px", right: "-8px" }}
          onClick={handleClose}
        >
          <Close sx={{ color: 'white' }} />
        </IconButton>
        <DialogContent sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          margin: "auto",
          textAlign: "center",
          overflow: "hidden",
          position: "relative",
          p: 0,
          gap: 2.75,
        }}>
          <Stack
            direction="row"
            sx={{
              transition: "transform 0.2s ease-in-out",
              transform: `translateX(-${activeStep * 100}%)`,
              width: "100%",
            }}
          >
            {slides.map((content, index) => (
              <Stack key={index} flexShrink={0} width="100%">
                {content}
              </Stack>
            ))}
          </Stack>

          <Stack direction="row" gap={2.5} justifyContent="center">
            {Array(maxSteps).fill('').map((_, index) => (
              <Circle
                key={index}
                sx={{
                  color: index === activeStep ? "primary.main" : "action.disabledBackground",
                  fontSize: 12,
                }}
              />
            ))}
          </Stack>

          <Stack direction="row" justifyContent="center" gap={1} px={2.5}>
            <Button
              size="large"
              onClick={handleBack}
              disabled={activeStep === 0}
              variant={activeStep === 0 ? "contained" : "outlined"}
              fullWidth
            >
              <FormattedMessage id="character.guide.back_button" />
            </Button>
            <Button
              size="large"
              onClick={() => {
                if (activeStep === slides.length - 1) {
                  handleClose();
                  return;
                }
                handleNext();
              }}
              variant="contained"
              fullWidth
            >
              <FormattedMessage id={activeStep === slides.length - 1
                ? "character.guide.close_button"
                : "character.guide.next_button"
              } />
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default CharacterGuideDialog;