import { Box, Button, Dialog, DialogActions, DialogContent, Stack, Typography } from '@mui/material';
import {
  useSelector
} from '@/services/hooks';
import dayjs from 'dayjs';
import { useLayoutEffect, useState } from 'react';
import { color } from '@/services/themes';
import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import { ApiService } from '@/services/api';
import { getLocale } from '@/utils';

const ignorePathNames = [
  // 決済結果ページでダイアログ出ると鬱陶しいので出さない
  '/users/plan/complete',
  '/users/plan/failed',
  '/users/plan',
]

const PlanLimitDialog = () => {
  const location = useLocation();
  const isTargetPath = ignorePathNames.findIndex((path) => location.pathname.includes(path)) === -1
  const { user, detail } = useSelector(state => state.user);
  const navigate = useNavigate();
  const expiredAt = !!detail?.paid_expired_at && dayjs(detail?.paid_expired_at).tz('Asia/Tokyo');
  const isTargetSubscription = expiredAt && expiredAt.isAfter(dayjs('2025-02-17')); // 疑似サブスクがリリースされたのは2025年1月17日なので、そこから1ヶ月後以降が期限になっているユーザーが対象
  const locale = getLocale();
  const now = dayjs()
  const diffDay = expiredAt && expiredAt.diff(now, 'day')
  const diffHour = expiredAt && expiredAt.diff(now, 'hour')
  const diffMinute = expiredAt && expiredAt.diff(now, 'minute')
  const isLimitSoon = isTargetSubscription
    && diffDay !== false && diffDay <= 14
    && diffMinute != false && diffMinute > 0
    && !user?.is_free_plan
  const isLimitOver = isTargetSubscription
    && diffMinute != false && diffMinute < 0
    && user?.is_free_plan
  const localeDay = locale === 'ja' ? '日' : 'days'
  const localeHour = locale === 'ja' ? '時間' : 'hours'
  const localeMinute = locale === 'ja' ? '分' : 'minutes'
  const diffMessage = (diffDay && `${diffDay}${localeDay}`) || (diffHour && diffMinute && `${diffHour}${localeHour}${diffMinute % 60}${localeMinute}`) || (diffMinute && `${diffMinute}${localeMinute}`)
  const [open, setOpen] = useState(false)
  const [sceneCounts, setSceneCounts] = useState({
    free: 0,
    bronze: 0,
  })
  useLayoutEffect(() => {
    if (isLimitSoon || isLimitOver) {
      setOpen(true)
    }
    if (isLimitSoon) {
      ApiService.getOriginalSceneCountEachPlan().then(res => {
        setSceneCounts(res)
      })
    }
  }, [isLimitSoon || isLimitOver])
  const onClose = () => {
    setOpen(false)
  }
  const displayType = isLimitSoon ? 'soon' : 'over'

  return (
    <Dialog
      open={open && isTargetPath}
      onClose={onClose}
      PaperProps={{
        sx: {
          p: 2,
          width: 'calc(100% - 20px)',
          maxWidth: '360px',
          margin: 0,
        }
      }}
    >
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          width: '100%',
          padding: 0,
          margin: 0,
        }}
      >
        <Stack gap={1}>
          <Typography fontWeight={700} fontSize={"24px"} textAlign={"center"} whiteSpace="pre-wrap">
            <FormattedMessage id={`plan_limit_dialog.${displayType}.title`} values={{ remain: diffMessage }} />
          </Typography>
          <Typography fontWeight={400} fontSize={"16px"} textAlign={"center"}>
            <FormattedMessage id={`plan_limit_dialog.${displayType}.subtitle`} />
          </Typography>
        </Stack>
        <Stack gap={1}>
          <Typography fontWeight={700} fontSize={"16px"} textAlign={"center"} color={'primary'}>
            <FormattedMessage id={`plan_limit_dialog.${displayType}.list_title`} />
          </Typography>
          {new Array(3).fill(0).map((_, i) => (
            <Stack direction="row" alignItems="center" key={i}>
              <Box sx={{ display: 'inline-block', width: '6px', height: '6px', borderRadius: '50%', backgroundColor: color.main, mx: 1 }} />
              <Typography fontWeight={400} fontSize={"14px"} whiteSpace={"pre-wrap"}>
                <FormattedMessage id={`plan_limit_dialog.${displayType}.list${i + 1}`} values={sceneCounts} />
              </Typography>
            </Stack>
          ))}
        </Stack>
      </DialogContent>
      <DialogActions sx={{
        flexDirection: 'column',
        padding: "20px 0 0 0",
      }}>
        <Button onClick={() => {
          navigate('users/plan')
          onClose()
        }} variant="contained" color="primary" fullWidth>
          <FormattedMessage id="plan_limit_dialog.plan_button" />
        </Button>
        <Typography fontWeight={400} fontSize={"12px"} textAlign={"center"} color={'text.secondary'} pt={1} pb={2.5}>
          <FormattedMessage id="plan_limit_dialog.button_annotation" />
        </Typography>
        <Button onClick={onClose} variant="outlined" fullWidth sx={{
          borderColor: "rgba(0, 0, 0, 0.12)",
          color: "rgba(0, 0, 0, 0.87)",
        }}>
          <FormattedMessage id="cancel" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PlanLimitDialog;
