import { Button, Divider, List, ListItem, ListItemText, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import { useSelector } from '@/services/hooks';
import PointItem from "@/components/PointItem";
import TagManager from "react-gtm-module";
import { isLimitedCampaignPeriod } from "@/utils";

type propType = {
  enableLink?: boolean
  disableShowPoint?: boolean
  showCampaign?: boolean
}

export default function CurrentPlan(props: propType) {
  const { enableLink, disableShowPoint, showCampaign } = props;
  const { detail, user } = useSelector((state) => state.user);
  const navigate = useNavigate();

  const handleMovePlan = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "gtm_event",
        ga_event: "move_plan_from_mypage",
        user_id: user?.id,
      }
    });
  }

  const isCampaignTarget = !!(
    (detail?.is_compensation_target && user?.is_free_plan && isLimitedCampaignPeriod()) // 補填対象者
    || (user?.is_free_plan && detail?.has_paid && isLimitedCampaignPeriod()) // 過去に課金経験あり
  )

  return (
    <>
      <ListItem disablePadding sx={{ backgroundColor: 'white', borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
        <ListItem component="div">
          <ListItemText
            disableTypography
            primary={<Typography variant="body2" color="rgba(0, 0, 0, 0.60)"><FormattedMessage id="current_plan.current_plan" /></Typography>}
            secondary={<Typography variant="h6" color="rgba(0, 0, 0, 0.87)">{`${detail?.plan_name}`} <FormattedMessage id="current_plan.plan" /></Typography>}
          />
          {
            enableLink && (
              <Link to="/users/plan" onClick={handleMovePlan}>
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                >
                  {<FormattedMessage id="current_plan.change_plan" />}
                </Button>
              </Link>)
          }
        </ListItem>
      </ListItem>
      {/* TODO: 決済復活キャンペーン終了したら消す */}
      {showCampaign && isCampaignTarget && (
        <ListItem
          onClick={() => navigate('/users/plan')}
          sx={{ backgroundColor: 'white', borderBottom: '1px solid rgba(0, 0, 0, 0.12)', padding: "16px", cursor: 'pointer' }}
        >
          <img src="/limited_time_banner.png" alt="決済復活キャンペーンバナー" style={{ width: '100%' }} />
        </ListItem>
      )}
      {/* ここまで */}
      {!disableShowPoint && (
        <ListItem disablePadding sx={{ backgroundColor: 'white', borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
          <ListItem component="div" sx={{ display: "block" }}>
            <List sx={{ padding: 0 }}>
              <ListItem>
                <PointItem
                  label={<FormattedMessage id="current_plan.switch_point" />}
                  description={<FormattedMessage id="current_plan.switch_point_description" />}
                  {...(detail ? detail.swap : { plan_remain: 0, plan_limit: 0 })}
                  disableLimit
                />
              </ListItem>
              <Divider sx={{ margin: '8px 0' }} />
              <ListItem>
                <PointItem
                  displayCurrent
                  label={<FormattedMessage id="current_plan.character" />}
                  description={<FormattedMessage id="current_plan.character_description" />}
                  {...(detail ? detail.character : { plan_remain: 0, plan_limit: 0 })}
                />
                <PointItem
                  displayCurrent
                  label={<FormattedMessage id="current_plan.favorite" />}
                  description={<FormattedMessage id="current_plan.favorite_description" />}
                  {...(detail ? detail.favorite : { plan_remain: 0, plan_limit: 0 })}
                />
              </ListItem>
            </List>
          </ListItem>
        </ListItem >
      )}

    </>
  )
}
